/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import { Context } from '../../Context/AuthContext';
import { HubContext } from '../../Context/HubContext.js';
import { useParams } from 'react-router-dom';
import { Request } from '../../scripts/Request';
import { Cache} from '../../scripts/Cache';
import { WaitObject } from '../../scripts/Wait';
import ReactGA from 'react-ga';
import './index.css';



export default function Index() {
    const component_name = "comofunciona";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const { Hub } = useContext(HubContext);
    const timerIdleRef = useRef(new Date());
    const loadPrestineRef = useRef(false);

    //#region Effects
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
        let wait = setTimeout(() => {
            if(!loadPrestineRef.current) {
                loadPrestineRef.current = true;
                Init();
            }
        }, 200);

        return () => {
            clearInterval(wait);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    Hub.Clear(component_name);
                } catch(e) {}
            }
        }
    },[]);
    //#endregion Effects


    //#region Init
    const Init = () => {    
        
    }
    //#endregion Init


    return (
        <>
        <div className="iq-blog-section overview-block-ptb">
            <div className="container">

                <div className="row">
                    <div className="col-lg-8 col-sm-12 mt-lg-0 mt-5">
                    <article id="post-218"
                        className="post-218 post type-post status-publish format-standard has-post-thumbnail hentry category-marketing tag-business tag-marketing">
                        <div className="iq-blog-box">
                            <div className="iq-blog-image clearfix">
                                <img src="images/marketing.png" className="img-fluid" alt="qloud" />
                                <ul className="iq-blogtag">
                                <li><a>Como funciona</a></li>
                                </ul>

                            </div>
                            <div className="iq-blog-detail">
                                <div className="iq-blog-meta">
                                <ul className="iq-postdate">
                                    <li className="list-inline-item">
                                        <br />
                                        <a rel="bookmark"><time className="entry-date published updated" datetime=""><h3>Parte 1: Serviço SUNUNDRIVE</h3></time></a>
                                    </li>
                                </ul>

                                </div>
                                <div className="blog-content">



                                <p>O SUNUNDRIVE é um serviço de armazenamento e backup de dados em nuvem, projetado para ser seguro, acessível e fácil de usar. Veja como funciona:</p>
                                <br />
                                
                                <blockquote className="wp-block-quote">
                                    <p>      <b>Envio de Arquivos:</b> <br /> <br />
                                Você seleciona os arquivos que deseja fazer backup e os envia para a nossa plataforma através do seu computador, tablet ou celular. <br />
                                A transferência dos arquivos é feita por upload diretamente para a nossa plataforma, onde são armazenados de forma segura.
                                </p>
                                </blockquote>

                                <blockquote className="wp-block-quote">
                                    <p> 
                                <b>Acesso de Qualquer Lugar:</b><br /> <br />
                                Após o upload, seus arquivos ficam disponíveis na nuvem e podem ser acessados de qualquer lugar do mundo, a qualquer momento.<br />
                                Isso proporciona flexibilidade e segurança, garantindo que você nunca perca seus dados importantes.
                                </p>
                                </blockquote>



                                <blockquote className="wp-block-quote">
                                    <p> 
                                    <b>Segurança e Conformidade com a LGPD:</b><br /> <br />
                                    Nossa infraestrutura segue rigorosamente as diretrizes da Lei Geral de Proteção de Dados (LGPD), garantindo a proteção e privacidade dos seus dados.<br />
                                    Diferentemente de outros serviços similares como Google Drive, Dropbox e OneDrive, o SUNUNDRIVE prioriza a conformidade com as normas brasileiras de proteção de dados, oferecendo uma camada extra de segurança e confiança.
                                    </p>
                                </blockquote>

                                <p>
                                    <div class="ratio ratio-16x9">
                                        <iframe src="https://www.youtube.com/embed/o6gHQ7dUIQ4" title="YouTube video" allowfullscreen></iframe>
                                    </div>
                                </p>

                                <br />
                                <a rel="bookmark"><time className="entry-date published updated" datetime=""><h3>Parte 2: Bonificação e Marketing Multinível</h3></time></a>


                                <p>O SUNUNDRIVE não é apenas um serviço de armazenamento em nuvem; também oferece uma oportunidade de ganho extra através do nosso programa de marketing multinível. Veja como funciona:</p>
                                <br />

                                <blockquote className="wp-block-quote">
                                    <p> 
                                    <b>Sistema de Bonificação:</b><br /> <br />
                                    Nosso programa de afiliados permite que você ganhe bonificações ao indicar novos clientes para a SUNUNDRIVE.<br />
                                    As bonificações são distribuídas em até 5 níveis, proporcionando ganhos recorrentes mensais com base nas assinaturas indicadas.
                                    </p>
                                </blockquote>

                                <blockquote className="wp-block-quote">
                                    <p> 
                                    <b>Níveis de Bonificação:</b><br /> <br />
                                    Primeiro Nível: R$ 10,00 por cada assinatura indicada diretamente por você.<br />
                                    Segundo Nível: R$ 5,00 por cada assinatura indicada pelos seus indicados diretos.<br />
                                    Terceiro Nível: R$ 3,00 por cada assinatura indicada pelo segundo nível.<br />
                                    Quarto Nível: R$ 2,00 por cada assinatura indicada pelo terceiro nível.<br />
                                    Quinto Nível: R$ 1,00 por cada assinatura indicada pelo quarto nível.<br />
                                    </p>
                                </blockquote>


                                <blockquote className="wp-block-quote">
                                    <p> 
                                    <b>Bonificação Extra por subida de Ranking:</b><br /> <br />

                                    Na SUNUNDRIVE, reconhecemos o esforço e o compromisso dos nossos afiliados em crescer dentro do nosso programa de afiliados. Para recompensar esse empenho, criamos um sistema de pontos que oferece bônus adicionais à medida que você alcança novos níveis de ranking.<br /><br />
<b>Como Funciona</b><br /><br />

Ao conquistar novos clientes diretos, você acumula pontos que contam para o seu ranking dentro do nosso programa. Atingir novos patamares não só solidifica a sua posição dentro da SUNUNDRIVE, mas também lhe dá direito a bônus financeiros exclusivos, que são liberados e mantidos conforme você sobe de nível e mantém sua rede de clientes diretos dentro das especificações.<br /><br />
<b>Tabela de Rankings e Bônus</b><br /><br />

BRONZE: &lt; 50 pessoas diretas (sem bônus)<br />
PRATA I: &gt;= 50 pessoas diretas (bônus adicional de R$ 1.000)<br />
PRATA II: &gt; 100 pessoas diretas (bônus adicional de R$ 2.000)<br />
PRATA III: &gt; 300 pessoas diretas (bônus adicional de R$ 10.000)<br />
OURO I: &gt; 500 pessoas diretas (bônus adicional de R$ 15.000)<br />
OURO II: &gt; 700 pessoas diretas (bônus adicional de R$ 25.000)<br />
OURO III: &gt; 1.000 pessoas diretas (bônus adicional de R$ 35.000)<br />
PLATINA I: &gt; 2.000 pessoas diretas (bônus adicional de R$ 50.000)<br />
PLATINA II: &gt; 5.000 pessoas diretas (bônus adicional de R$ 100.000)<br />
PLATINA III: &gt; 10.000 pessoas diretas (bônus adicional de R$ 250.000)<br />
DIAMANTE I: &gt; 50.000 pessoas diretas (bônus adicional de R$ 500.000)<br />
DIAMANTE II: &gt; 100.000 pessoas diretas (bônus adicional de R$ 1.000.000)<br />
DIAMANTE III: &gt; 300.000 pessoas diretas (bônus adicional de R$ 5.000.000)<br />
PRESIDENTE: &gt; 500.000 pessoas diretas (bônus adicional de R$ 10.000.000)<br />
                                    </p>
                                </blockquote>

                                <blockquote className="wp-block-quote">
                                    <p> 
                                    <b>Benefícios da Subida de Ranking:</b><br /> <br />
                                    Ao subir de ranking, você não só recebe um bônus financeiro, mas também ganha reconhecimento dentro da nossa rede. Cada novo nível representa um marco importante em sua trajetória na SUNUNDRIVE, refletindo seu crescimento e sucesso contínuo. Estes bônus são recorrentes, desde que você mantenha sua rede de clientes diretos dentro das especificações para o seu ranking.
                                    </p>
                                </blockquote>

                                <blockquote className="wp-block-quote">
                                    <p> 
                                    <b>Perda e Recuperação de Bônus:</b><br /> <br />
                                    Os bônus são recalculados mensalmente. Caso o número de clientes diretos caia, seu ranking pode ser ajustado para um nível inferior, e o bônus será pago conforme o novo ranking. Por exemplo, se você é PRATA III e perde alguns clientes, fazendo com que o número de diretos caia para abaixo de 300, você passará a receber o bônus de PRATA II ou PRATA I, dependendo do novo total de clientes diretos. Se cair abaixo de 50, você retorna ao ranking BRONZE e perde o bônus extra.<br /><br />
                                    No entanto, se você recuperar o número de clientes diretos e retornar ao seu ranking anterior, o bônus correspondente será restabelecido automaticamente no próximo ciclo de pagamento.
                                    </p>
                                </blockquote>

                                <blockquote className="wp-block-quote">
                                    <p> 
                                    <b>Dicas para Subir e Manter seu Ranking:</b><br /> <br />
                                    Expansão de Rede: Concentre-se em expandir sua rede de clientes diretos. Quanto mais pessoas você trouxer para a SUNUNDRIVE, mais rápido você poderá subir de ranking e manter seu nível.<br /><br />
    Aproveite o Programa de Indicação: Utilize o seu link pessoal para compartilhar com amigos, familiares e conhecidos. Incentive-os a se juntarem à nossa plataforma e comece a acumular pontos.<br /><br />
    Foco em Qualidade: Construa relacionamentos sólidos com seus indicados, oferecendo suporte e acompanhamento. Isso não só ajuda na retenção de clientes, mas também aumenta as chances de indicações adicionais, contribuindo para a manutenção do seu ranking.<br />
                                    </p>
                                </blockquote>


                                <blockquote className="wp-block-quote">
                                    <p> 
                                    <b>Deduções de Impostos e Taxas Administrativas:</b><br /> <br />
                                    Os valores das bonificações sofrem uma dedução de 11% referente ao INSS + alíquota do IR que dependerá do valor bruto ganho dentro da plataforma. Tudo retido na fonte conforme exigido pela legislação brasileira.
                                    </p>
                                </blockquote>

                                <p>
                                    <div class="ratio ratio-16x9">
                                        <iframe src="https://www.youtube.com/embed/xPjf-TFqsTk" title="YouTube video" allowfullscreen></iframe>
                                    </div>
                                </p>


                                </div>

                            </div>
                        </div>
                       
                    </article>
                    </div>


                    <div className="col-lg-4 col-sm-12 mt-lg-0 mt-5">
                        <aside id="secondary" className="widget-area" aria-label="Blog Sidebar">
                            <div id="search-2" className="widget widget_search">
                                <h4>Simulação de Ganhos</h4>
                                <small>Todos as transferências mensais são recolhidos os tributos.</small>
                            </div>
                            <div id="recent-posts-2" className="widget widget_recent_entries">
                                <h2 className="widget-title">Primeiro Nível</h2>
                                Se você indicar diretamente 10 assinaturas, você receberá:<br />
                                R$ 10,00 x 10 = R$ 100,00<br />
                                {/*Após deduções (35%):<br /> 
                                R$ 100,00 - R$ 35,00 = R$ 65,00*/}
                                
                            </div>

                            <div id="recent-posts-2" className="widget widget_recent_entries">
                                <h2 className="widget-title">Segundo Nível</h2>
                                Se cada uma dessas 10 pessoas indicarem outras 10, você terá:<br />
                                R$ 5,00 x 100 = R$ 500,00<br />
                                {/*Após deduções (35%):<br /> 
                                R$ 500,00 - R$ 175,00 = R$ 325,00*/}  
                            </div>

                            <div id="recent-posts-2" className="widget widget_recent_entries">
                                <h2 className="widget-title">Terceiro Nível</h2>
                                Se cada uma dessas 100 pessoas indicarem outras 10, você terá:<br />
                                R$ 3,00 x 1.000 = R$ 3.000,00<br />
                                {/*Após deduções (35%):<br /> 
                                R$ 3.000,00 - R$ 1.050,00 = R$ 1.950,00*/}
                            </div>

                            <div id="recent-posts-2" className="widget widget_recent_entries">
                                <h2 className="widget-title">Quarto Nível</h2>
                                Se cada uma dessas 1.000 pessoas indicarem outras 10, você terá:<br />
                                R$ 2,00 x 10.000 = R$ 20.000,00<br />
                                {/*Após deduções (35%):<br /> 
                                R$ 20.000,00 - R$ 7.000,00 = R$ 13.000,00*/}
                            </div>

                            <div id="recent-posts-2" className="widget widget_recent_entries">
                                <h2 className="widget-title">Quinto Nível</h2>
                                Se cada uma dessas 10.000 pessoas indicarem outras 10, você terá:<br />
                                R$ 1,00 x 100.000 = R$ 100.000,00<br />
                                {/*Após deduções (35%):<br /> 
                                R$ 100.000,00 - R$ 35.000,00 = R$ 65.000,00*/}
                            </div>

                            <div id="recent-posts-2" className="widget widget_recent_entries">
                                <h2 className="widget-title">Total Mensal</h2>
                                1º Nível: R$ 100,00 <br />
                                2º Nível: R$ 500,00 <br />
                                3º Nível: R$ 3.000,00 <br />
                                4º Nível: R$ 20.000,00 <br />
                                5º Nível: R$ 100.000,00 <br />
                                <br />
                                <small>R$ 123.600,00 (bruto)</small>
                                <h4 style={{color:'red'}}> R$ 89.885,07</h4> <small><b>Após tributos</b></small>
                            </div>
                            
                        </aside>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}