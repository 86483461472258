/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import { Context } from '../../Context/AuthContext';
import { HubContext } from '../../Context/HubContext.js';
import { useParams } from 'react-router-dom';
import { Request, Req } from '../../scripts/Request';
import { Cache} from '../../scripts/Cache';
import { WaitObject } from '../../scripts/Wait';
import { Currency } from '../../scripts/StringUtils';
import ReactGA from 'react-ga';
import './index.css';


export default function Plano() {
    const component_name = "admin/plano";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const { Hub } = useContext(HubContext);
    const timerIdleRef = useRef(new Date());
    const loadPrestineRef = useRef(false);
    const [pageLoading, setPageLoading] = useState(false);
    const [affiliate, setAffiliate] = useState("");


    //#region Affiliate
    let { Id } = useParams();

   
    //#endregion Affiliate


     //#region Effects
     useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);

        setTimeout(async() => {
            let affiliate = await Cache.Get("affiliate");
            if(affiliate) {
                setAffiliate(affiliate);
            }
        }, 100)
            

        let wait = setTimeout(() => {
            if(!loadPrestineRef.current) {
                loadPrestineRef.current = true;
                Init();
            }
        }, 200);


        return () => {
            clearInterval(wait);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    Hub.Clear(component_name);
                } catch(e) {}
            }
        }
    },[]);
    //#endregion Effects
    


    //#region Init
    const Init = () => {
        
     }
    //#endregion Init



    return (
        <>
       <div className="iq-blog-section overview-block-ptb">
            <div className="container">

                <div className="row">
                    <div className="col-lg-8 col-sm-12 mt-lg-0 mt-5">
                    <article id="post-218"
                        className="post-218 post type-post status-publish format-standard has-post-thumbnail hentry category-marketing tag-business tag-marketing">
                        <div className="iq-blog-box">
                            <div className="iq-blog-image clearfix">
                                <img src="/images/privacidade.png" className="img-fluid" alt="sobre o SUNUNDRIVE" />
                                <ul className="iq-blogtag">
                                    {Id === "cloud-basic" && (<li><a>CLOUD BACKUP BASIC</a></li>)}
                                    {Id === "cloud-personal" && (<li><a>CLOUD BACKUP PERSONAL</a></li>)}
                                    {Id === "cloud-professional" && (<li><a>CLOUD BACKUP PROFESSIONAL</a></li>)}
                                    {Id === "cloud-expansion" && (<li><a>CLOUD BACKUP EXPANSION</a></li>)}
                                    {Id === "cloud-max" && (<li><a>CLOUD BACKUP MAX</a></li>)}
                                    {Id === "cloud-premium" && (<li><a>CLOUD BACKUP PREMIUM</a></li>)}
                                </ul>

                            </div>
                            <div className="iq-blog-detail">
                                <div className="iq-blog-meta">
                                

                                </div>
                                <div className="blog-content">
        
                                <br />

                                

                                {Id === "cloud-basic" && (<>
                                <p>
                                    <h4>R$ 149,90/Mês</h4>
                                </p>

                                <p>
                                O plano <b>CLOUD BACKUP BASIC</b> é a solução ideal para quem busca simplicidade e segurança para armazenar documentos e fotos pessoais. Com a SUNUNDRIVE, seus dados estão sempre protegidos e acessíveis de qualquer lugar.
                                </p>

                                <br />
                                <p>
                                    <h5>O que você recebe:</h5>
                                </p>

                                <p>
                                - <b>15 GB de Armazenamento</b>: Espaço perfeito para guardar seus arquivos essenciais, como documentos e fotos pessoais.
                                </p>
                                <p>
                                - <b>Backup em Nuvem</b>: Seus dados são armazenados com segurança na nuvem, garantindo que estejam sempre protegidos contra perda ou danos.
                                </p>
                                <p>
                                - <b>Suporte por Ticket - Horário Comercial</b>: Conte com nosso suporte técnico disponível durante o horário comercial para qualquer dúvida ou assistência que precisar.
                                </p>

                                <br />
                                <p>
                                    <h5>Segurança e Criptografia</h5>
                                </p>

                                <p>
                                Seus dados são mantidos seguros com criptografia avançada, tanto durante a transferência quanto no armazenamento na nuvem. Utilizamos uma plataforma estável e confiável, desenvolvida com base em tecnologias open-source, garantindo que seus arquivos estejam sempre protegidos.
                                </p>

                                <br />
                                <p>
                                    <h5>100% Online e Seguro</h5>
                                </p>

                                <p>
                                Com a SUNUNDRIVE, você não precisa instalar nada. Nossa solução é totalmente online, acessível de qualquer dispositivo com conexão à internet. Além disso, estamos em total conformidade com a Lei Geral de Proteção de Dados (LGPD), assegurando que suas informações pessoais sejam tratadas com o máximo de cuidado e respeito.
                                </p>

                                <br />
                                <p>
                                    <h5>Programa de Afiliados e Subida de Ranking</h5>
                                </p>
                                
                                <p>
                                    Participe do nosso Programa de Afiliados e ganhe comissões recorrentes ao indicar novos clientes para a SUNUNDRIVE. E mais, à medida que você expande sua rede, pode subir de ranking e desbloquear bônus adicionais. Mantenha sua rede ativa e continue crescendo para obter ainda mais benefícios.
                                </p>

                                <br />
                                <center>
                                    <a className="iq-button iq-btn-round d-inline" href={'https://painel.sunundrive.com/Cadastro/'+ affiliate + '?plan_id=7'}>
                                    Contrate Agora
                                    </a>
                                </center>

                                <br /><br />

                                </>)}

                                {Id === "cloud-personal" && (<>
                                <p>
                                    <h4>R$ 189,90/Mês</h4>
                                </p>

                                <p>
                                O plano <b>CLOUD BACKUP PERSONAL</b> é perfeito para quem deseja preservar memórias digitais e documentos importantes com segurança e praticidade. Com mais espaço, você pode armazenar uma variedade maior de arquivos, desde fotos até documentos importantes.
                                </p>

                                <br />
                                <p>
                                    <h5>O que você recebe:</h5>
                                </p>

                                <p>
                                - <b>30 GB de Armazenamento</b>: Ideal para guardar uma quantidade maior de arquivos, incluindo fotos, vídeos e documentos importantes.
                                </p>
                                <p>
                                - <b>Backup em Nuvem</b>: Seus arquivos são armazenados com segurança na nuvem, garantindo que estejam sempre protegidos e acessíveis.
                                </p>
                                <p>
                                - <b>Suporte por Ticket - Horário Comercial</b>: Nosso suporte técnico está à disposição durante o horário comercial para te ajudar com qualquer necessidade.
                                </p>

                                <br />
                                <p>
                                    <h5>Segurança e Criptografia</h5>
                                </p>

                                <p>
                                A segurança dos seus dados é nossa prioridade. Utilizamos criptografia avançada para garantir que seus arquivos estejam protegidos tanto durante a transferência quanto no armazenamento. Nossa plataforma, baseada em tecnologias robustas e open-source, oferece estabilidade e confiança.
                                </p>

                                <br />
                                <p>
                                    <h5>100% Online e Seguro</h5>
                                </p>

                                <p>
                                Não é necessário instalar nenhum software. A SUNUNDRIVE é uma solução 100% online, acessível de qualquer lugar. Além disso, estamos comprometidos com a LGPD, garantindo que seus dados pessoais sejam tratados com a máxima segurança e conformidade.
                                </p>

                                <br />
                                <p>
                                    <h5>Programa de Afiliados e Subida de Ranking</h5>
                                </p>
                                
                                <p>
                                Ganhe comissões recorrentes ao indicar novos clientes para a SUNUNDRIVE através do nosso Programa de Afiliados. Quanto mais você expande sua rede, mais você pode subir de ranking e desbloquear bônus adicionais. Continue crescendo com a SUNUNDRIVE e aproveite todos os benefícios.
                                </p>

                                <br />
                                <center>
                                    <a className="iq-button iq-btn-round d-inline" href={'https://painel.sunundrive.com/Cadastro/'+ affiliate + '?plan_id=8'}>
                                    Contrate Agora
                                    </a>
                                </center>

                                <br /><br />
                                </>)}

                                {Id === "cloud-professional" && (<>
                                <p>
                                    <h4>R$ 239,90/Mês</h4>
                                </p>

                                <p>
                                Se você precisa de mais espaço para organizar sua biblioteca digital, o <b>CLOUD BACKUP PROFESSIONAL</b> é a escolha certa. Com maior capacidade de armazenamento e segurança reforçada, este plano é ideal para quem precisa de mais espaço para arquivos essenciais.
                                </p>

                                <br />
                                <p>
                                    <h5>O que você recebe:</h5>
                                </p>

                                <p>
                                - <b>50 GB de Armazenamento</b>: Espaço suficiente para guardar todos os seus arquivos importantes, desde documentos de trabalho até coleções de fotos e vídeos.
                                </p>
                                <p>
                                - <b>Backup em Nuvem</b>: Mantenha seus arquivos protegidos e acessíveis a qualquer momento com nossos backups automáticos e seguros.
                                </p>
                                <p>
                                - <b>Suporte por Ticket - Horário Comercial</b>: Conte com nosso suporte técnico dedicado durante o horário comercial para qualquer dúvida ou necessidade.
                                </p>

                                <br />
                                <p>
                                    <h5>Segurança e Criptografia</h5>
                                </p>

                                <p>
                                Seus dados são criptografados com tecnologia avançada, garantindo segurança total tanto durante a transferência quanto no armazenamento. Nossa plataforma, estável e baseada em tecnologias open-source, oferece a confiança que você precisa para gerenciar seus arquivos com tranquilidade.
                                </p>

                                <br />
                                <p>
                                    <h5>100% Online e Seguro</h5>
                                </p>

                                <p>
                                A SUNUNDRIVE é uma solução totalmente online, dispensando a necessidade de instalação de qualquer software. Acesse seus arquivos de qualquer dispositivo com internet. Além disso, estamos em plena conformidade com a LGPD, garantindo que suas informações pessoais sejam tratadas de forma segura e transparente.
                                </p>

                                <br />
                                <p>
                                    <h5>Programa de Afiliados e Subida de Ranking</h5>
                                </p>
                                
                                <p>
                                Aproveite o Programa de Afiliados da SUNUNDRIVE para aumentar seus ganhos. Compartilhe o serviço com sua rede de contatos e receba comissões recorrentes. Expanda sua rede e suba de ranking para desbloquear bônus e benefícios adicionais.
                                </p>

                                <br />
                                <center>
                                    <a className="iq-button iq-btn-round d-inline" href={'https://painel.sunundrive.com/Cadastro/'+ affiliate + '?plan_id=9'}>
                                    Contrate Agora
                                    </a>
                                </center>

                                <br /><br />
                                </>)}

                                {Id === "cloud-expansion" && (<>
                                <p>
                                    <h4>R$ 259,90/Mês</h4>
                                </p>

                                <p>
                                O <b>CLOUD BACKUP EXPANSION</b> oferece o espaço ideal para aqueles que precisam armazenar projetos e lembranças digitais de forma segura e acessível. Com 100 GB de armazenamento, este plano é perfeito para quem precisa de mais espaço e segurança.
                                </p>

                                <br />
                                <p>
                                    <h5>O que você recebe:</h5>
                                </p>

                                <p>
                                - <b>100 GB de Armazenamento</b>: Espaço amplo para guardar todos os seus projetos, arquivos profissionais e coleções pessoais.
                                </p>
                                <p>
                                - <b>Backup em Nuvem</b>: Seus arquivos são protegidos por backups automáticos e criptografia avançada, garantindo segurança e acessibilidade a qualquer momento.
                                </p>
                                <p>
                                - <b>Suporte por Ticket - Horário Comercial</b>: Nosso suporte técnico está disponível durante o horário comercial para oferecer a melhor assistência possível.
                                </p>

                                <br />
                                <p>
                                    <h5>Segurança e Criptografia</h5>
                                </p>

                                <p>
                                Seus dados são armazenados com segurança na nuvem, utilizando criptografia de ponta para proteger suas informações. Nossa plataforma, baseada em tecnologias open-source, é estável e confiável, garantindo que seus arquivos estejam sempre seguros.
                                </p>

                                <br />
                                <p>
                                    <h5>100% Online e Seguro</h5>
                                </p>

                                <p>
                                Não há necessidade de instalar nada. A SUNUNDRIVE oferece uma solução 100% online, acessível de qualquer dispositivo com conexão à internet. Estamos comprometidos com a LGPD, garantindo que seus dados sejam tratados de forma segura e em conformidade com as leis brasileiras.
                                </p>

                                <br />
                                <p>
                                    <h5>Programa de Afiliados e Subida de Ranking</h5>
                                </p>
                                
                                <p>
                                Participe do nosso Programa de Afiliados e ganhe comissões recorrentes ao indicar novos clientes para a SUNUNDRIVE. À medida que você expande sua rede, pode subir de ranking e desbloquear bônus adicionais. Mantenha sua rede ativa e continue crescendo para obter ainda mais benefícios.
                                </p>

                                <br />
                                <center>
                                    <a className="iq-button iq-btn-round d-inline" href={'https://painel.sunundrive.com/Cadastro/'+ affiliate + '?plan_id=10'}>
                                    Contrate Agora
                                    </a>
                                </center>

                                <br /><br />
                                </>)}

                                {Id === "cloud-max" && (<>
                                <p>
                                    <h4>R$ 339,90/Mês</h4>
                                </p>

                                <p>
                                Com o <b>CLOUD BACKUP MAX</b> você pode armazenar toda a sua vida digital em um único lugar, com segurança e acessibilidade garantidas. Este plano oferece 500 GB de armazenamento para que você possa guardar todos os seus arquivos importantes.
                                </p>

                                <br />
                                <p>
                                    <h5>O que você recebe:</h5>
                                </p>

                                <p>
                                - <b>500 GB de Armazenamento</b>: Espaço suficiente para armazenar uma grande quantidade de dados, incluindo documentos, fotos, vídeos e outros arquivos importantes.
                                </p>
                                <p>
                                - <b>Backup em Nuvem</b>: Proteja seus arquivos com backups automáticos e criptografia avançada, garantindo que seus dados estejam sempre seguros e acessíveis.
                                </p>
                                <p>
                                - <b>Suporte por Ticket - Horário Comercial</b>: Conte com nosso suporte técnico especializado durante o horário comercial para qualquer dúvida ou assistência.
                                </p>

                                <br />
                                <p>
                                    <h5>Segurança e Criptografia</h5>
                                </p>

                                <p>
                                Seus dados são criptografados e armazenados com total segurança na nuvem. Nossa plataforma, desenvolvida com base em tecnologias open-source, oferece estabilidade e confiança para que você possa armazenar seus arquivos com tranquilidade.
                                </p>

                                <br />
                                <p>
                                    <h5>100% Online e Seguro</h5>
                                </p>

                                <p>
                                A SUNUNDRIVE é uma solução totalmente online, acessível de qualquer dispositivo com conexão à internet. Não é necessário instalar nenhum software. Estamos em conformidade com a LGPD, garantindo que seus dados pessoais sejam tratados com a máxima segurança e respeito às leis brasileiras.
                                </p>

                                <br />
                                <p>
                                    <h5>Programa de Afiliados e Subida de Ranking</h5>
                                </p>
                                
                                <p>
                                Aumente seus ganhos com o Programa de Afiliados da SUNUNDRIVE. Indique novos clientes e receba comissões recorrentes. Expanda sua rede, suba de ranking e desbloqueie bônus adicionais enquanto continua a crescer conosco.
                                </p>

                                <br />
                                <center>
                                    <a className="iq-button iq-btn-round d-inline" href={'https://painel.sunundrive.com/Cadastro/'+ affiliate + '?plan_id=11'}>
                                    Contrate Agora
                                    </a>
                                </center>

                                <br /><br />
                                </>)}

                                {Id === "cloud-premium" && (<>
                                <p>
                                    <h4>R$ 399,90/Mês</h4>
                                </p>

                                <p>
                                O <b>CLOUD BACKUP PREMIUM</b> é o nosso plano mais completo, oferecendo 1TB de armazenamento para todas as suas necessidades digitais. Armazene grandes volumes de dados com total segurança e acessibilidade.
                                </p>

                                <br />
                                <p>
                                    <h5>O que você recebe:</h5>
                                </p>

                                <p>
                                - <b>1 TB de Armazenamento</b>: Espaço de sobra para armazenar grandes volumes de dados, incluindo vídeos em alta resolução, projetos profissionais e muito mais.
                                </p>
                                <p>
                                - <b>Backup em Nuvem</b>: Seus arquivos são protegidos com backups automáticos e criptografia avançada, garantindo que estejam sempre seguros e acessíveis.
                                </p>
                                <p>
                                - <b>Suporte por Ticket - Horário Comercial</b>: Nosso suporte técnico especializado está à disposição durante o horário comercial para oferecer a melhor assistência.
                                </p>

                                <br />
                                <p>
                                    <h5>Segurança e Criptografia</h5>
                                </p>

                                <p>
                                A segurança dos seus dados é garantida por criptografia avançada e uma plataforma estável baseada em tecnologias open-source. Seus arquivos estarão sempre protegidos e acessíveis na nuvem, com total confiança.
                                </p>

                                <br />
                                <p>
                                    <h5>100% Online e Seguro</h5>
                                </p>

                                <p>
                                Com a SUNUNDRIVE, você não precisa se preocupar em instalar nada. Nossa solução é totalmente online e acessível de qualquer lugar, em qualquer dispositivo. Estamos comprometidos com a conformidade com a LGPD, garantindo que seus dados sejam tratados de forma segura e em conformidade com a legislação brasileira.
                                </p>

                                <br />
                                <p>
                                    <h5>Programa de Afiliados e Subida de Ranking</h5>
                                </p>
                                
                                <p>
                                Transforme sua experiência com a SUNUNDRIVE em uma fonte de renda com o nosso Programa de Afiliados. Indique novos clientes, ganhe comissões recorrentes e suba de ranking para desbloquear bônus adicionais. Continue crescendo conosco e aproveite todos os benefícios.
                                </p>

                                <br />
                                <center>
                                    <a className="iq-button iq-btn-round d-inline" href={'https://painel.sunundrive.com/Cadastro/'+ affiliate + '?plan_id=12'}>
                                    Contrate Agora
                                    </a>
                                </center>

                                <br /><br />
                                </>)}

                               

                                </div>

                            </div>
                        </div>
                       
                    </article>
                    </div>


                    <div className="col-lg-4 col-sm-12 mt-lg-0 mt-5">
                    <aside id="secondary" className="widget-area" aria-label="Blog Sidebar">
                        
                        <div id="recent-posts-2" className="widget widget_recent_entries">
                            <img src="/images/dispositivos.png" className="img-fluid" alt="sobre o SUNUNDRIVE" /> <br /><br />
                            Transferência rápida e eficiente de seu dispositivo para nossa nuvem.
                        </div>


                        <div id="recent-posts-2" className="widget widget_recent_entries">
                            <img src="/images/seguranca.png" className="img-fluid" alt="sobre o SUNUNDRIVE" /> 
                            Segurança de ponta com alta disponibilidade para seus dados mais valiosos.
                        </div>

                        <div id="recent-posts-2" className="widget widget_recent_entries">
                            <img src="/images/renda.jpg" className="img-fluid" alt="sobre o SUNUNDRIVE" /> 
                            Ganhe renda extra mensal com nosso programa de afiliados por indicações que você faz de nossa plataforma.
                        </div>

                        <div id="recent-posts-2" className="widget widget_recent_entries">
                            <img src="/images/marketing.png" className="img-fluid" alt="sobre o SUNUNDRIVE" /> <br /><br />
                            Expanda suas oportunidades com nosso sistema de marketing multinível com até 5 níveis de bonificação por vendas diretas.
                        </div>
                    </aside>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}