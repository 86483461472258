/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import { Context } from '../../Context/AuthContext';
import './index.css'
import { Currency } from '../../scripts/StringUtils';
import { Cache} from '../../scripts/Cache';

export default function Footer() {
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);

    const [affiliate, setAffiliate] = useState("");
    const [filiados, setFiliados] = useState("");
    const [isCalcPage, setIsCalcPage] = useState(window.location.href.toLowerCase().indexOf("/calculadora") > -1);


    //#region Effects
    useEffect(() => {
        setTimeout(async() => {
            let affiliate = await Cache.Get("affiliate");
            if(affiliate) {
                setAffiliate(affiliate);
            }
        }, 1000)
    
    },[]);
    //#endregion Effects


    const _HandleChange = (event) => {
        setFiliados(event.target.value);
    }


    const _HandlerCalc = (event) => {
        event.preventDefault();

        if(filiados && filiados !== "") {
            let niveis = [10,5,3,2,1];
            let size = parseInt(filiados);
            let sum = 0;

            for(let n=0; n<5; n++) {
                sum += niveis[n] * Math.pow(size,n+1);
            }

            let r = "O valor mensal do seu bônus pode chegar até " + Currency(sum*0.65);
            window.swal("Calculadora", r, "success");
        }
    }
       


    return (
        <footer id="contact" className=" iq-over-dark-90">
            <div className="footer-topbar">
                <div className="container">
                    {!isCalcPage && (
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 ">
                            <div className="widget">
                                <div className="textwidget">
                                    <div className="row ">
                                        {/*<div className="col-lg-6 mb-5 mb-lg-0 text-center text-lg-left">
                                            <h2 className="text-white">Calculadora de Bônus</h2>
                                        </div>
                                        <div className="col-lg-6 mb-5 mb-lg-0 text-center text-lg-right">
                                            <form className="mc4wp-form mc4wp-form-517" onSubmit={_HandlerCalc }>
                                                <div className="mc4wp-form-fields">
                                                    <input type="number" id="filiados" name="filiados" value={filiados} placeholder="Numero de filiados" required="" onChange={_HandleChange}/>
                                                    <input className="btn_calc" type="submit" value="Calcular" />
                                                </div>
                                            </form>
                                        </div>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    )}
                </div>
            </div>
        
            <div className="footer-top">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-6 col-sm-6 ">
                            <div className="widget">
                                <div className="textwidget ">
                                    <p><a href="/"> <img className="mb-4 img-fluid" src="/images/logo.png" alt="qloud" /></a>
                                        <br /> Confiável para Seus Arquivos, Rentável para o Seu Bolso.</p>
                                        <br /> <a href='https://www.privacidade.com.br/portal-de-privacidade?token=c8d8e1eeea4291787eb27d156fba1bee' target="_blank"><img src='https://api.dponet.com.br/selo_portal_de_privacidade_branco.png' style={{width:'230px',height:'auto'}}/></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 mt-4 mt-lg-0 mt-md-0 ">
                            <div className="widget">
                                <h4 className="footer-title">Menu</h4>
                                <div>
                                    <ul className="menu">
                                        <li><a href="/Planos" aria-current="page">Planos</a></li>
                                        <li><a href="/ComoFunciona">Como funciona</a></li>
                                        <li><a href={"https://painel.sunundrive.com/Cadastro/" + affiliate}>Torne-se um afiliado</a></li>
                                        <li><a href="https://blog.sunundrive.com" target="_blank">Blog</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 mt-lg-0 mt-4">
                            <div className="widget">
                                <h4 className="footer-title ">Quem somos</h4>
                                <div className="menu-about">
                                    <ul id="menu-about-menu" className="menu">
                                        <li><a href="/Sobre">Sobre a SUNUNDRIVE</a></li>
                                        <li><a href="/Faq">Perguntas frequentes</a></li>
                                        <li><a href="/Termos">Termos de uso</a></li>
                                        <li><a href="/Privacidade">Política de privacidade</a></li>
                                        <li><a href="/PoliticaPagamento">Política de pagamento</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
      
                        <div className="col-lg-3 col-md-6 col-sm-6 mt-lg-0 mt-4">
                            <div className="widget">
                                <h4 className="footer-title ">Contato</h4>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <ul className="iq-contact">
                                            <li>
                                                <a><i className="fa fa-home"></i><span>Avenida Yojiro Takaoka, 4384 - Sala 701 - Alphaville - Santana do Parnaiba - CEP 06541-038</span></a>
                                            </li>
                                            <li>
                                                <a><i className="fa fa-building"></i><span>REWARDX TECHNOLOGY SERVICES LTDA</span></a>
                                            </li>
                                            <li>
                                                <a><i className="fa fa-puzzle-piece"></i><span>CNPJ: 55.427.186/0001-51</span></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           
            <div className="copyright-footer">
                <div className="container">
                    <div className="pt-3 pb-3">
                        <div className="row justify-content-between">
                            <div className="col-lg-6 col-md-12 text-lg-left text-md-center text-center">
                                <div className="social-icone">
                                    <ul>
                                        <li className="d-inline"><a href="https://x.com/sunundrive" target="_blank"><i className="fa fa-twitter"></i></a></li>
                                        <li className="d-inline"><a href="https://www.instagram.com/sunundrive" target="_blank"><i className="fa fa-instagram"></i></a></li>
                                        <li className="d-inline"><a href="https://www.linkedin.com/company/sunundrive" target="_blank"><i className="fa fa-linkedin"></i></a></li>
                                        <li className="d-inline"><a href="https://www.youtube.com/@sunundrive" target="_blank"><i className="fa fa-youtube"></i></a></li>
                                        <li className="d-inline"><a href="https://web.facebook.com/sunundrive" target="_blank"><i className="fa fa-facebook"></i></a></li>
                                        <li className="d-inline"><a href="https://whatsapp.com/channel/0029Vam14XTK5cDOc9FMNB0D" target="_blank"><i className="fa fa-whatsapp"></i></a></li>
                                        <li className="d-inline"><a href="https://www.threads.net/@sunundrive" target="_blank">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="16" fill="currentColor" class="bi bi-threads" viewBox="1 2 16 16">
                                            <path d="M6.321 6.016c-.27-.18-1.166-.802-1.166-.802.756-1.081 1.753-1.502 3.132-1.502.975 0 1.803.327 2.394.948s.928 1.509 1.005 2.644q.492.207.905.484c1.109.745 1.719 1.86 1.719 3.137 0 2.716-2.226 5.075-6.256 5.075C4.594 16 1 13.987 1 7.994 1 2.034 4.482 0 8.044 0 9.69 0 13.55.243 15 5.036l-1.36.353C12.516 1.974 10.163 1.43 8.006 1.43c-3.565 0-5.582 2.171-5.582 6.79 0 4.143 2.254 6.343 5.63 6.343 2.777 0 4.847-1.443 4.847-3.556 0-1.438-1.208-2.127-1.27-2.127-.236 1.234-.868 3.31-3.644 3.31-1.618 0-3.013-1.118-3.013-2.582 0-2.09 1.984-2.847 3.55-2.847.586 0 1.294.04 1.663.114 0-.637-.54-1.728-1.9-1.728-1.25 0-1.566.405-1.967.868ZM8.716 8.19c-2.04 0-2.304.87-2.304 1.416 0 .878 1.043 1.168 1.6 1.168 1.02 0 2.067-.282 2.232-2.423a6.2 6.2 0 0 0-1.528-.161"/>
                                            </svg>
                                        </a></li>
                                        <li className="d-inline"><a href="https://www.tiktok.com/@sunundrive" target="_blank">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-tiktok" viewBox="1 2 16 16">
                                            <path d="M9 0h1.98c.144.715.54 1.617 1.235 2.512C12.895 3.389 13.797 4 15 4v2c-1.753 0-3.07-.814-4-1.829V11a5 5 0 1 1-5-5v2a3 3 0 1 0 3 3z"/>
                                            </svg>
                                        </a></li>
                                        
                                        
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12 text-lg-right text-md-center text-center">
                                <span className="copyright">Copyright <span id="copyright"> 
                                
                                </span> SUNUNDRIVE</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}