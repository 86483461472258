/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import { Context } from '../../Context/AuthContext';
import { Request } from '../../scripts/Request';
import { HubContext } from '../../Context/HubContext.js';
import { WaitObject } from '../../scripts/Wait';
import { Cache} from '../../scripts/Cache';
import { LinkContainer } from 'react-router-bootstrap';
import $ from 'jquery';
import { useNavigate } from 'react-router-dom';

import './index.css';



export default function Header() {
    const component_name = "admin/header";
    const { user, handleLogout, Update } = useContext(Context);
    const { Hub } = useContext(HubContext);
    const [affiliate, setAffiliate] = useState("");
    

    const unidadesRef = useRef([]);
    const timerIdleRef = useRef(new Date());
    const loadPrestineRef = useRef(false);
    const navigate = useNavigate();


    //#region Effects
    useEffect(() => {
        setTimeout(async() => {
            let affiliate = await Cache.Get("affiliate");
            if(affiliate) {
                setAffiliate(affiliate);
            }
        })
    
    },[]);
    //#endregion Effects



    return (
        <>
        <header id="main-header" className="header-main">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <nav className="navbar navbar-expand-lg navbar-light">
                            <a className="navbar-brand" href="/">
                                <img className="img-fluid" src="/images/logo.png" alt="img" />
                            </a>
                            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="menu-btn d-inline-block" id="menu-btn">
                                <span className="line"></span>
                                <span className="line"></span>
                                <span className="line"></span>
                                </span>
                                <span className="ion-navicon"></span>
                            </button>
                            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                <ul className="navbar-nav mr-auto w-100 justify-content-end">
                                    <li className="nav-item dropdown">

                                        <a className="nav-link" id="navbarDropdown" href="/Home">Home</a>
                                      
                                    </li>
                                    <li className="nav-item dropdown">
                                        
                                        <a className="nav-link " id="navbarDropdown1" href="/Planos">Planos</a>
                                        
                                    </li>
                                    <li className="nav-item dropdown">
                                        <a className="nav-link dropdown-toggle" href="javascript:void(0)" id="navbarDropdown-2" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Afiliados</a>
                                        <div className="dropdown-menu" aria-labelledby="navbarDropdown-2">
                                            <a className="dropdown-item" href="/ComoFunciona">Como funciona?</a>
                                            <a className="dropdown-item" href={"https://painel.sunundrive.com/Cadastro/" + affiliate}>Torne-se um afiliado</a>
                                        </div>
                                    </li>
                                    <li className="nav-item dropdown">
                                        <a className="nav-link dropdown-toggle" href="javascript:void(0)" id="navbarDropdown-3" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Quem somos</a>
                                        <div className="dropdown-menu" aria-labelledby="navbarDropdown-3">
                                            <a className="dropdown-item" href="/Sobre">Sobre a SUNUNDRIVE</a>
                                            <a className="dropdown-item" href="/Faq">Pergunta frequentes</a>
                                            <a className="dropdown-item" href="https://blog.sunundrive.com" target="_blank">Blog</a>
                                            <a className="dropdown-item" href="/Termos">Termos de uso</a>
                                            <a className="dropdown-item" href="/Privacidade">Política de privacidade</a>
                                            <a className="dropdown-item" href="/PoliticaPagamento">Política de pagamento</a>
                                        </div>
                                    </li>
                                    <li className="nav-item dropdown">
                                      
                                            <a className="nav-link " id="navbarDropdown" href="/Contato">Contato</a>
                                        
                                    </li>

                                    <li className="nav-item dropdown entrar_conta">
                                        <a className="nav-link" id="navbarDropdown" href="https://painel.sunundrive.com" target="_blank">Entrar na Conta</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="sub-main">
                                <ul>
                                    <li className="d-inline"><a href="https://painel.sunundrive.com" className="login iq-button iq-btn-outline iq-btn-round" target="_blank">Entrar na Conta</a></li>
                                    <li className="d-inline" id="btn_new_cad"><a href={"https://painel.sunundrive.com/Cadastro/" + affiliate} className="signup iq-button default iq-btn-round" target="_blank">Novo Cadastro</a></li>
                                </ul>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </header>
        </>
    );
}