/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import { Context } from '../../Context/AuthContext';
import { HubContext } from '../../Context/HubContext.js';
import { useParams } from 'react-router-dom';
import { Request } from '../../scripts/Request';
import { Cache} from '../../scripts/Cache';
import { WaitObject } from '../../scripts/Wait';
import ReactGA from 'react-ga';
import './index.css';



export default function Index() {
    const component_name = "privacidade";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const { Hub } = useContext(HubContext);
    const timerIdleRef = useRef(new Date());
    const loadPrestineRef = useRef(false);

    //#region Effects
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
        let wait = setTimeout(() => {
            if(!loadPrestineRef.current) {
                loadPrestineRef.current = true;
                Init();
            }
        }, 200);

        return () => {
            clearInterval(wait);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    Hub.Clear(component_name);
                } catch(e) {}
            }
        }
    },[]);
    //#endregion Effects


    //#region Init
    const Init = () => {    
        
    }
    //#endregion Init


    return (
        <>
       <div className="iq-blog-section overview-block-ptb">
            <div className="container">

                <div className="row">
                    <div className="col-lg-8 col-sm-12 mt-lg-0 mt-5">
                    <article id="post-218"
                        className="post-218 post type-post status-publish format-standard has-post-thumbnail hentry category-marketing tag-business tag-marketing">
                        <div className="iq-blog-box">
                            <div className="iq-blog-image clearfix">
                                <img src="images/termos.png" className="img-fluid" alt="sobre o SUNUNDRIVE" />
                                <ul className="iq-blogtag">
                                <li><a>POLÍTICA DE PAGAMENTO SUNUNDRIVE</a></li>
                                </ul>

                            </div>
                            <div className="iq-blog-detail">
                                <div className="iq-blog-meta">
                                Esta Política de Pagamentos integra os Termos Gerais de Uso da Sunundrive. Você deve ler as condições a seguir de forma atenta e cuidadosa pois esta Política contempla informações importantes a respeito do Programa de Afiliados Sunundrive.<br /><br />

                                <small>Data de Publicação: 27/08/2024<br />
                                Data da Entrada em Vigor: 27/08/2024</small>

                                </div>
                                <div className="blog-content">
        
                                    <br />
                                    <h5>SOBRE A POLÍTICA DE PAGAMENTO SUNUNDRIVE</h5>
                                    <br />

                                    <ul class="letter-list">
                                        <li>A presente Política de Pagamento define as regras e procedimentos para a remuneração dos Afiliados envolvidos nas atividades de venda direta da Sunundrive.  Esta política visa garantir transparência, equidade e conformidade com as regulamentações aplicáveis.</li>
                                        <li>Os termos desta Política constituem um contrato vinculante entre você, como Afiliado, e a Sunundrive.</li>
                                        <li>Ao aceitar esta Política, você declara que conhece e concorda com o seu conteúdo e com as demais Políticas aplicáveis, inclusive com a Política de Privacidade, que, apesar de apresentadas em textos separados, são partes integrantes dos Termos Gerais de Uso da Sunundrive.</li>
                                    </ul>

                                    <br /><br />
                                    
                                    <p>
                                    <b>1.	DADOS BANCÁRIOS:</b> Os Afiliados, a fim de receber os valores das transações efetuadas por meio da Plataforma devem indicar a conta corrente de sua titularidade que conste no mesmo domicílio de seu cadastro. A conta informada deve ser da titularidade do respectivo Afiliado, sendo proibido o cadastro de conta de terceiro. Os valores recebidos não podem ser repassados para conta de terceiros ou para conta com domicílio diferente daquela  que fez constar no seu cadastro.
                                    </p>
                                    <br /><br />

                                    <p>
                                    <b>2.	ESTRUTURA DE REMUNERAÇÃO</b> 
                                    </p>

                                    <p>
                                    2.1. Vendas direta: Os Usuários receberão comissões sobre as vendas diretas de serviços que realizarem. A taxa de comissão consiste no valor de R$10,00 (dez reais) sobre a venda realizada.
                                    </p>

                                    <p>
                                    2.2. Vendas da Rede: Além das comissões sobre vendas diretas, os Afiliados também receberão comissões sobre as vendas realizadas pelos distribuidores que recrutarem para a sua rede. As comissões serão calculadas com base na estrutura de níveis e na política de compensação da Sunundrive. Os níveis referem-se a construção da sua rede de recrutamento e o valor de bonificação em cada um dos níveis é de:<br/><br/>
                                    i. nível 1 - bonificação de R$10,00 (dez reais) para cada venda realizada nesse nível.<br />
                                    ii. nível 2 - bonificação de R$5,00 (cinco reais) para cada venda realizada nesse nível.<br />
iii. nível 3 - bonificação de R$3,00 (três reais) para cada venda realizada nesse nível. <br />
iv. nível 4 - bonificação de R$2,00 (dois reais) para cada venda realizada nesse nível.<br />
v. nível 5 - bonificação de R$1,00 (um real) para cada venda realizada nesse nível.
                                    </p>

                                    <p>
                                    2.3. Bônus e Incentivos: A Sunundrive poderá oferecer bônus e incentivos adicionais com base no desempenho dos Afiliados, como metas de vendas alcançadas, recrutamento de novos distribuidores e avanço de nível dentro da estrutura de marketing multinível.
                                    </p>

                                    <p>
                                    <b>2.4. Bônus Vigentes e Regras:</b> <br />
                                    <p>2.4.1. O número de membros referidos na tabela são aqueles contabilizados apenas no nível 1 da rede do Afiliado;<br />
2.4.2. Para que o Afiliado faça jus à bonificação, ele deverá permanecer no Pódio relativo à bonificação pelo prazo mínimo de 3 meses para, após, receber sua recompensa. <br />
2.4.3. O Afiliado será bonificado apenas 1 vez em cada pódio. Havendo desclassificação para um pódio menor, não será possível receber a bonificação novamente quando retornar ao próximo pódio, estado em que se encontrava antes da classificação. 
</p>

                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Pódio</th>
                                                <th>Nº de membros Nível 1</th>
                                                <th>Recompensa</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr>
                                                <td>Bronze</td>
                                                <td>até 49</td>
                                                <td>R$ 0,00</td>
                                            </tr>

                                            <tr>
                                                <td>Prata I</td>
                                                <td>de 50 a 99</td>
                                                <td>R$ 1.000,00</td>
                                            </tr>

                                            <tr>
                                                <td>Prata II</td>
                                                <td>de 100 a 299</td>
                                                <td>R$ 2.000,00</td>
                                            </tr>

                                            <tr>
                                                <td>Prata III</td>
                                                <td>de 300 a 499</td>
                                                <td>R$ 10.000,00</td>
                                            </tr>

                                            <tr>
                                                <td>Ouro I</td>
                                                <td>de 500 a 699</td>
                                                <td>R$ 15.000,00</td>
                                            </tr>

                                            <tr>
                                                <td>Ouro II</td>
                                                <td>de 700 a 999</td>
                                                <td>R$ 25.000,00</td>
                                            </tr>

                                            <tr>
                                                <td>Ouro III</td>
                                                <td>de 1.000 a 1.999</td>
                                                <td>R$ 35.000,00</td>
                                            </tr>

                                            <tr>
                                                <td>Platina I</td>
                                                <td>de 2.000 a 4.999</td>
                                                <td>R$ 50.000,00</td>
                                            </tr>

                                            <tr>
                                                <td>Platina II</td>
                                                <td>de 5.000 a 9.999</td>
                                                <td>R$ 100.000,00</td>
                                            </tr>

                                            <tr>
                                                <td>Platina III</td>
                                                <td>de 10.000 a 49.999</td>
                                                <td>R$ 250.000,00</td>
                                            </tr>

                                            <tr>
                                                <td>Diamante I</td>
                                                <td>de 50.000 a 99.999</td>
                                                <td>R$ 500.000,00</td>
                                            </tr>

                                            <tr>
                                                <td>Diamante II</td>
                                                <td>de 100.000 a 299.999</td>
                                                <td>R$ 1.000.000,00</td>
                                            </tr>

                                            <tr>
                                                <td>Diamante III</td>
                                                <td>de 300.000 a 499.999</td>
                                                <td>R$ 5.000.000,00</td>
                                            </tr>

                                            <tr>
                                                <td>Presidente</td>
                                                <td>a partir de 500.000</td>
                                                <td>R$ 10.000.000,00</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    </p>
                                    <br /><br />


                                    <p>
                                    <b>3.	GESTÃO DE PAGAMENTOS, TRIBUTAÇÃO E TAXAS</b> <br />
                                    </p>

                                    <p>
                                    3.1 Modalidades de Gestão de Pagamentos e Funções. A funcionalidade financeira referente à gestão de pagamento na Plataforma corresponde ao pagamento aos Afiliados pela venda do Serviço da Plataforma, bem como pela venda do Serviço realizada por sua rede de afiliados recrutados.
                                    </p><p>3.2. Poderes Outorgados à Sunundrive. No momento em que se cadastra na Plataforma, o Afiliado concorda e outorga poderes nos termos,  autorizando que a Sunundrive promova:
                                    </p><p>3.2.1. O recebimento do preço pago pelo Usuário;
                                    </p><p>3.2.2. O pagamento, em nome e por conta do Usuário, das comissões estabelecidas ao Afiliado pela venda do Serviço e por vendas realizadas por sua rede de afiliados recrutados, quando houver;
                                    </p><p>3.3. Natureza dos Serviços Prestados. O Afiliado declara-se ciente de que as funcionalidades financeiras, em especial de gestão de pagamentos contidas na Plataforma, não são e nem se destinam a ser comparáveis aos serviços financeiros oferecidos por instituições bancárias ou administradoras de cartão de crédito, consistindo apenas em uma forma de facilitação e acompanhamento da realização de transações para pagamentos e recebimentos entre os Usuários e Afiliado da Plataforma.
                                    </p><p>3.4. Responsabilidade Tributária do Afiliado. O Afiliado é o único responsável por determinar, recolher, reter, declarar e remeter tributos, tributos de importação, taxas, sobretaxas e cobranças adicionais aplicáveis que sejam decorrentes ou resultem de qualquer venda ou de seu uso dos Serviços. De acordo com a legislação aplicável, cada Afiliado é responsável pelo recolhimento dos impostos/retenções originados em razão das transações realizadas na Plataforma. Os Afiliados são responsáveis pelo pagamento de todos os impostos aplicáveis sobre as comissões recebidas, de acordo com a legislação vigente em sua jurisdição.
                                    </p><p>3.5. Responsabilidade Tributária da Sunundrive: A Sunundrive poderá reter impostos na fonte conforme exigido por lei. Todas as retenções serão detalhadas no comprovante de pagamento fornecido ao Afiliado.
                                        <p style={{marginLeft:'25px'}}>
                                        3.5.1. As tributações a serem descontadas sobre o valor bruto das comissões são:
                                            <ul style={{marginLeft:'25px', listStyleType: 'lower-alpha'}}>
                                                <li>11% (onze por cento) correspondente aos valores de INSS, sendo o valor máximo a ser descontado é de R$856,46 (oitocentos e cinquenta e seis reais e quarenta e seis centavos); e </li>
                                                <li>descontos variáveis a depender do ganho mensal ocorrerão de acordo com a tabela progressiva abaixo, nos termos da lei, conforme Imposto de Renda, com vigência para o ano de 2024.</li>
                                            </ul>

                                            <br />
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Faixa</th>
                                                        <th>Base de Cálculo (R$)</th>
                                                        <th>Alíquota (%)</th>
                                                        <th>Pacela a deduzir do IR (R$) </th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    <tr>
                                                        <td>1º</td>
                                                        <td>Até 2.259,20</td>
                                                        <td>Isento</td>
                                                        <td>0</td>
                                                    </tr>
                                                    <tr>
                                                        <td>2º</td>
                                                        <td>De 2.259,21 até 2.826,65</td>
                                                        <td>7,5</td>
                                                        <td>169,44</td>
                                                    </tr>
                                                    <tr>
                                                        <td>3º</td>
                                                        <td>De 2.826,66 até 3.751,05</td>
                                                        <td>15</td>
                                                        <td>381,44</td>
                                                    </tr>
                                                    <tr>
                                                        <td>4º</td>
                                                        <td>De 3.751,06 até 4.664,68</td>
                                                        <td>22,5</td>
                                                        <td>662,77</td>
                                                    </tr>
                                                    <tr>
                                                        <td>5º</td>
                                                        <td>Acime de 4.664,68</td>
                                                        <td>27,5</td>
                                                        <td>896,00</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <br />
                                        </p>
                                        
                                    </p><p>3.6. Obrigações do Usuário. O Usuário deverá manter uma forma de pagamento válida no cadastro para pagar todas as Taxas incorridas e recorrentes. A Sunundrive cobrará as Taxas aplicáveis usando a forma de pagamento válida que o Usuário  autorizar, e a Sunundrive continuará a usar a Forma de Pagamento Autorizada para realizar a cobrança das Taxas aplicáveis até que o Serviço seja encerrado e todas as Taxas pendentes tenham sido integralmente pagas. 
                                    </p>
                                    <br /><br />

                                    <p>
                                    <b>4.	PROCESSAMENTO DE PAGAMENTO:</b> <br />
                                    </p>
                                    <p>
                                    4.1. Realização de pagamento: Uma vez que a Sunundrive recebe a confirmação de que o pagamento do Usuário foi aprovada, a Sunundrive deve disponibilizar os valores devidos ao Afiliado em sua conta bancária, ressalvadas as hipóteses em que a Sunundrive está autorizada a suspender ou cancelar os pagamentos, na forma dos Termos e desta Política. A Sunundrive deve disponibilizar esses valores também observando: (a) a eventual ocorrência de chargebacks ou pedidos de reembolso e (b) o transcurso dos prazos de garantia ou exercício de direito de arrependimento pelo Usuário.
                                    </p><p>4.2. Responsabilidade do Afiliado pelos dados informados:  O Afiliado deve fornecer informações verdadeiras, completas e exatas. A Sunundrive não é responsável por quaisquer danos, patrimoniais ou extrapatrimoniais, resultantes de qualquer falha, inveracidade ou incompletude imputável a estes Usuários no momento em que preencherem as informações referentes ao Cadastro na Plataforma. 
                                    </p>
                                    <br /><br />

                                    <p>
                                    <b>5.	PAGAMENTOS</b> <br />
                                    </p>
                                    <p>5.1. Periodicidade: Os pagamentos serão efetuados em até 60 dias após a confirmação da venda.
                                    </p><p>5.2. Método de Pagamento: Os pagamentos serão realizados via transferência bancária para a conta informada pelo Afiliado no momento do cadastro. É responsabilidade do Afiliado manter suas informações bancárias atualizadas junto à Sunundrive
                                    </p><p>5.3. Máximo/Mínimo: Será estabelecido um valor mínimo de comissão acumulada para que o pagamento seja efetuado. Caso o Afiliado não atinja o valor mínimo em um mês, o saldo será transferido para o mês seguinte até que o valor mínimo seja atingido.
                                    </p><p>5.4. Relatórios de Comissão: A Sunundrive fornecerá relatórios detalhados de comissões e bônus, disponíveis no portal do Afiliado. Estes relatórios incluirão informações sobre vendas diretas, vendas da rede, bônus e quaisquer deduções aplicáveis.
                                    </p>
                                    <br /><br />

                                    <p>
                                    <b>6.	ERRO DE TRANSFERÊNCIA: </b> <br />
                                    </p>  
                                    <p>
                                    A Sunundrive se reserva o direito de corrigir quaisquer erros de pagamento identificados, seja a favor ou contra o Afiliado. As correções serão refletidas no próximo ciclo de pagamento. 
                                    </p>  
                                    <br /><br />

                                    <p>
                                    <b>7.	ENCERRAMENTO DE CONTA </b> <br />
                                    </p>  
                                    <p>
                                    7.1. A Conta de Pagamento de titularidade do Afiliado poderá ser encerrada pelos seguintes motivos: <br />
                                    </p><p>7.1.1. Pela própria iniciativa do Afiliado, e a qualquer momento, condicionado à inexistência de pendências como, mas não limitado a: (a) inconsistências e irregularidades nas informações cadastrais fornecidas à Sunundrive; (b) questões relativas à idoneidade do Afiliado; (c) reclamações pendentes; (d) transações pendentes, em processamento ou ainda não liquidadas, (v) eventuais obrigações de pagamento vinculadas à Conta de Pagamento que estejam pendentes, tais como, mas não se limitando a débitos perante a Sunundrive.

                                    </p><p>7.1.2 Por iniciativa da Sunundrive, incluindo, mas não se limitando aos seguintes casos: (a) em razão de ordem de autoridade competente; (b) por qualquer irregularidade nas informações prestadas pelo Afiliado; (c) por descumprimento de qualquer disposição desta Política ou de quaisquer outras regras e condições de cadastro; (d) por desinteresse comercial na manutenção da Conta de Pagamento e (e) em caso de Conta de Pagamento inativa, assim entendidas aquelas sem movimentação pelo prazo de, no mínimo, 12 (doze) meses;

                                    </p><p>7.1.3 Por realizar uma troca de titularidade de Conta de Pagamento, uma vez que, neste caso, automaticamente ocorrerá a transferência das responsabilidades para o novo titular, e, portanto, a Conta de Pagamento será encerrada, sendo criada uma nova Conta de Pagamento para o novo titular;

                                    </p><p>7.2. Comunicação sobre o encerramento: Na hipótese de encerramento da Conta de Pagamento, em quaisquer das hipóteses acima, será enviada uma comunicação, pela Sunundrive, em um dos meios de comunicação informados no cadastro, tal como, mas não limitado ao endereço de e-mail cadastrado pelo Usuário ou Afiliado na Plataforma, com informações claras sobre o motivo do encerramento, saldo remanescente e prazos e procedimentos para retirada deste, caso haja.

                                    </p><p>7.3. Suspeita de Fraude e Medidas de Prevenção na Conta de Pagamento: Os pagamentos efetuados e os saldos mantidos em Conta de Pagamento poderão ser bloqueados total ou parcialmente, a critério da Sunundrive, conforme este Termo.

                                    </p><p>7.4. Bloqueio de Valores na Conta de Pagamento: Os valores eventualmente bloqueados em decorrência de práticas irregulares ou das razões acima poderão ser retirados da Conta de Pagamento e utilizados pela Sunundrive para o cumprimento de ordem judicial, para o ressarcimento de eventuais custos, despesas ou danos causados à Sunundrive ou a terceiros.

                                    </p><p>7.5. Saldo remanescente:Em caso de encerramento da conta do Afiliado, por qualquer motivo, o saldo de comissão remanescente será pago no ciclo de pagamento seguinte, desde que atenda ao valor mínimo de pagamento estabelecido.

                                    </p><p>7.6. Discrepância: Caso o Afiliado identifique qualquer discrepância nos valores pagos, deverá notificar a Sunundrive por escrito no prazo de 30 dias a partir da data de pagamento. A Sunundrive investigará a reclamação e, se necessário, efetuará os ajustes apropriados no pagamento subsequente.

                                    </p>  
                                    <br /><br />
                                 

                                    <p>
                                    <b>8.	MEDIDAS DE PREVENÇÃO A FRAUDES E SUSPENSÃO DE PAGAMENTOS</b> <br />
                                    </p>  
                                    <p>
                                    </p><p>8.1. Irregularidades na conta do Usuário: Por força da regulamentação aplicável, a Sunundrive poderá  efetuar verificações sobre dados fornecidos pelos Afiliados antes de autorizar o ingresso do Programa de Afiliados.  A Sunundrive informará os Afiliados que estiverem com cadastro irregular ou incompleto. A Sunundrive poderá bloquear qualquer operação de saque e suspender o acesso a links de vendas do Afiliado, se o Afiliado não providenciar os ajustes necessários em 30 dias contados da notificação da Sunundrive a respeito.

                                    </p><p>8.2. Suspeitas de fraude: Todas as transações de pagamento efetuadas são verificadas pelos mecanismos de gestão de risco integrados à Plataforma, e podem ser recusadas sempre que a Sunundrive entender que há indícios de fraude aos meios de pagamento, de infração à lei ou aos Termos Gerais de Uso da Sunundrive.

                                    </p><p>8.3. Medidas preventivas relativas a transações de pagamento: A Sunundrive pode recusar a aprovação de uma transação, bloquear a realização de tranferências ou bloquear Contas de Pagamento, a qualquer tempo, mesmo após a confirmação de pagamento de uma transação, nas seguintes hipóteses, sem prejuízo de outras: (a) se a Sunundrive entender que há indícios de que o Usuário ou Afiliado, estão em desacordo com os Termos Gerais de Uso da Sunundrive; (b) se a Sunundrive constatar que as informações relativas à transação são incompletas, inverídicas ou imprecisas; (c) se a Sunundrive observar que a adoção de quaisquer dessas medidas é necessária para proteger a segurança da própria Sunundrive ou de seus empregados, colaboradores, representantes, agentes, sócios ou parceiros; (d) se a Sunundrive for incluída em qualquer processo judicial ou administrativo a respeito de uma transação de pagamento e o Usuário ou Afiliado não tomar as medidas necessárias para excluir a Sunundrive do respectivo processo, isentando-a de qualquer responsabilidade, conforme descrito nos Termos Gerais de Uso e nesta Política; (e) se a Sunundrive tomar conhecimento de que o Usuário deixou de atender a ordem de órgãos públicos; (f) em cumprimento a qualquer exigência legal, decisão judicial ou determinação de autoridade pública competente dirigida à Sunundrive; (i) se a Sunundrive verificar a existência de valores vencidos e não pagos pelo Usuário ou Afiliado.

                                    </p><p>8.4. Bloqueios de Transferências: Em qualquer das hipóteses previstas no parágrafo anterior, se você não sanar o problema referente às informações relativas às transações, não prestar esclarecimentos satisfatórios ou não oferecer as garantias necessárias exigidas pela Sunundrive, essa poderá recusar em definitivo a aprovação da transação de pagamento, ou mesmo bloquear seu saldo na Plataforma.

                                    </p>  
                                    <br /><br />


                                    <p>
                                    <b>9.	Alterações na Política: </b> <br />
                                    </p>  

                                    <p>
                                    A Sunundrive reserva-se o direito de alterar esta Política de Pagamento a qualquer momento. Todas as alterações serão comunicadas aos Usuários e Afiliados por meio dos canais oficiais de comunicação da Sunundrive e entrarão em vigor imediatamente após a publicação.
                                    </p>

                                    <br /><br /><br />
                                </div>

                            </div>
                        </div>
                       
                    </article>
                    </div>


                    <div className="col-lg-4 col-sm-12 mt-lg-0 mt-5">
                    <aside id="secondary" className="widget-area" aria-label="Blog Sidebar">
                        
                        <div id="recent-posts-2" className="widget widget_recent_entries">
                            <img src="images/dispositivos.png" className="img-fluid" alt="sobre o SUNUNDRIVE" /> <br /><br />
                            Transferência rápida e eficiente de seu dispositivo para nossa nuvem.
                        </div>


                        <div id="recent-posts-2" className="widget widget_recent_entries">
                            <img src="images/seguranca.png" className="img-fluid" alt="sobre o SUNUNDRIVE" /> 
                            Segurança de ponta com alta disponibilidade para seus dados mais valiosos.
                        </div>

                        <div id="recent-posts-2" className="widget widget_recent_entries">
                            <img src="images/renda.jpg" className="img-fluid" alt="sobre o SUNUNDRIVE" /> 
                            Ganhe renda extra mensal com nosso programa de afiliados por indicações que você faz de nossa plataforma.
                        </div>

                        <div id="recent-posts-2" className="widget widget_recent_entries">
                            <img src="images/marketing.png" className="img-fluid" alt="sobre o SUNUNDRIVE" /> <br /><br />
                            Expanda suas oportunidades com nosso sistema de marketing multinível com até 5 níveis de bonificação por vendas diretas.
                        </div>
                    </aside>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}