/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import { Context } from '../../Context/AuthContext';
import { HubContext } from '../../Context/HubContext.js';
import { useParams } from 'react-router-dom';
import { Req, Request } from '../../scripts/Request';
import { Cache} from '../../scripts/Cache';
import { WaitObject } from '../../scripts/Wait';
import { useNavigate } from 'react-router-dom';
import StringMask from 'string-mask';
import ReactGA from 'react-ga';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import './index.css';


const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const dept = urlParams.get('dep');

const contatos = [
    {id: 1, group: "Contato", subject: "Assuntos Gerais", description: "Caso você ainda não seja um usuário da SUNUNDRIVE e queira falar sobre outro assunto."},
    {id: 2, group: "Cadastro", subject: "Problemas durante o cadastro", description: "Para você que esta com dificuldade em realizar/finalizar o seu cadastro."},
    {id: 3, group: "Suporte Tecnico", subject: "Problemas no acesso ao painel - Usuários já cadastrados", description: "Para você que já é um cliente porem esta enfrentando dificuldades em acessar o painel administrativo da sua conta."},
    {id: 4, group: "Comercial", subject: "Solicitação para Planos Empresariais", description: "Para cotação de planos para empresas. Por favor, Informar CNPJ, Numero Aproximado de Usuários e quantidade de espaço em disco necessário."},
    {id: 5, group: "Comercial", subject: "Parcerias Comerciais", description: "Contato para parceria entre empresas, oferta de produtos e/ou soluções, integração com outras plataformas, empresas gateways de pagamento, bancos e/ou fintechs."},
    {id: 6, group: "Influencer", subject: "Novo cadastro Influencer", description: "Para que você é influencer digital, a SUNUNDRIVE possui uma proposta especial para voce. Entre em contato e não esqueça de informar suas redes sociais, numero de seguidores e público."},
    {id: 7, group: "Ouvidoria", subject: "Ouvidoria", description: "Para sugestões, elogios, reclamações ou denúncias. Esse canal de comunicação garante a transparência e o bom funcionamento dos nossos serviços."},
];



export default function Index() {
    const component_name = "contato";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const { Hub } = useContext(HubContext);
    const [pageLoading, setPageLoading] = useState(false);
    const [desc, setDesc] = useState(contatos[0].description);
    const [form, setForm] = useState({nome:"", email: "", telefone:"", mensagem:"", subject_id:(dept?dept:1)});
    const timerIdleRef = useRef(new Date());
    const loadPrestineRef = useRef(false);
    
    const navigate = useNavigate();

    //#region Effects
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
        let wait = setTimeout(() => {
            if(!loadPrestineRef.current) {
                loadPrestineRef.current = true;
                Init();
            }
        }, 200);

        return () => {
            clearInterval(wait);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    Hub.Clear(component_name);
                } catch(e) {}
            }
        }
    },[]);


    useEffect(() => {
        if(loading || pageLoading) {
            window.w_loadObj(".Contato")
        } else {
            window.w_unloadAll();
        }
     }, [loading, pageLoading]);
    //#endregion Effects


    //#region Init
    const Init = () => {    
        
    }
    //#endregion Init


    //#region Handlers
    const _HandleUpdate = (event) => {
        let value = event.target.value;

        if(event.target.id === "subject_id") {
            setDesc(contatos.find(c => c.id === parseInt(event.target.value))?.description);
        } else if(event.target.id === "telefone") {
            value = value.replace(/[^\d]+/g, '');
            let formatter = new StringMask("(99) 99999-9999");
            value = formatter.apply(value);
        }

        setForm({...form, [event.target.id]: value });
    }


    const _HandleSubmit = async(name, event) => {
        event.preventDefault();
      
        if(form.nome === "" 
        || form.email === ""
        || form.telefone === ""
        || form.mensagem === ""
        || form.subject_id === "") {
            window.swal("Alerta", "Preencha todo formulário!", "error");
            return;
        }

        setPageLoading(true);
        let _result = await Req("api/Contato/Send", "POST", {...form, ...contatos.find(c => c.id === parseInt(form.subject_id)) }, "");
        setPageLoading(false);
        

        if(_result.toString() === "loaded") {
            window.swal("Sucesso", "Mensagem enviada com sucesso!", "success");
            setTimeout(() => { 
                if(user.token === '') {
                    navigate('/Login'); 
                } else {
                    navigate('/');
                }
            }, 300);
        
        } else {
            window.swal("Alerta", "Não foi possível enviar a mensagem, tente novamente mais tarde!", "warning");
        }
    }
    //#endregion Handlers


    return (
        <GoogleReCaptchaProvider reCaptchaKey="6LfIIAIqAAAAAD8ScdUFJ9YcxTmKGYIF_zhwYQZ8" language="pt-BR">
        <div className="Contato overview-block-ptb">
            <br />
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-12">
                        <img src="images/others/01.png" className="img-fluid" alt="contact" />
                    </div>
                    <div className="col-lg-6 col-md-12">
                        <div className=" text-left iq-title-box iq-title-default iq-title-box-2">
                            <div className="iq-title-icon">
                            </div>
                            
                            <h2 className="iq-title">
                        Formulário de Contato   </h2>

                               
                        </div>
                        <div role="form" className="wpcf7" id="wpcf7-f790-p785-o1" lang="en-US" dir="ltr">
                            <div className="screen-reader-response"></div>
                            <form name="fuser" onSubmit={(event) => _HandleSubmit('rcontato', event)} autoComplete="off" id="form-contact" className="wpcf7-form"noValidate>
                            
                                <div className="row">
                                    <div className="col-lg-12">
                                        <label className="contato_label">Seu nome</label>
                                        <span className="wpcf7-form-control-wrap first-name"><input type="text" id="nome" name="first-name" value={form.nome} size="40" className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required" aria-required="true" aria-invalid="false" placeholder="" onChange={_HandleUpdate}/></span>
                                    </div>

                                    <div className="col-lg-12">
                                        <label className="contato_label">Assunto desejado</label>
                                        <span className="wpcf7-form-control-wrap first-name">
                                            <select id="subject_id" value={form.subject_id} className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required" onChange={_HandleUpdate}>
                                                {
                                                    Array.isArray(contatos) && contatos.length > 0 && contatos.map((item, i) => (
                                                        <option key={'contato'+i} value={item.id}>{item.subject}</option>
                                                    ))
                                                }
                                            </select>
                                        </span>

                                        <div className="description">
                                           {desc}
                                        </div>
                                        <br />
                                    </div>
                                    
                  
                                    <div className="col-lg-6">
                                        <label className="contato_label">E-mail</label>
                                        <span className="wpcf7-form-control-wrap your-email"><input type="email" id="email" name="email" value={form.email} size="40" className="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email" aria-required="true" aria-invalid="false" placeholder="" onChange={_HandleUpdate}/></span>
                                    </div>
                                    <div className="col-lg-6">
                                        <label className="contato_label">Telefone Celular</label>
                                        <span className="wpcf7-form-control-wrap tel-554"><input type="tel" id="telefone" name="telefone" value={form.telefone} mask="(99) 99999-9999" size="40" className="wpcf7-form-control wpcf7-text wpcf7-tel wpcf7-validates-as-tel" aria-invalid="false" placeholder="" onChange={_HandleUpdate}/></span>
                                    </div>
                                    <div className="col-lg-12">
                                        <p> 
                                            <label className="contato_label">Mensagem</label>
                                            <span className="wpcf7-form-control-wrap your-message"><textarea id="mensagem" name="emnsagem" cols="40" rows="10" className="wpcf7-form-control wpcf7-textarea" aria-invalid="false" placeholder="" value={form.mensagem} onChange={_HandleUpdate}>{form.mensagem}</textarea></span>
                                        </p>
                                    </div>
                                    <div className="col-lg-12">
                                        <input type="submit" value="Enviar mensagem" className="wpcf7-form-control wpcf7-submit" />
                                    </div>
                                </div>
                                <div className="wpcf7-response-output wpcf7-display-none"></div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </div>


        <section className="light-gray-bg">
        <div className="container">
            <div className="row">
                <div className="col-lg-4  col-md-6">
                    <div className="iq-process iq-process-step-style-2 text-center">

                        <div className="iq-process-step">

                            <div className="iq-step-content">
                                <i aria-hidden="true" className="ion ion-ios-location"></i> </div>
                            <div className="iq-step-text-area">

                                <h4 className="iq-step-title">Endereço</h4>

                                <span className="iq-step-desc">Avenida Yojiro Takaoka, 4384 - Sala 701 - Alphaville - Santana do Parnaiba - CEP 06541-038</span>

                            </div>

                        </div>

                    </div>
                </div>
                <div className="col-lg-4  col-md-6">
                    <div className="iq-process iq-process-step-style-2 text-center">

                        <div className="iq-process-step">

                            <div className="iq-step-content">
                                <i aria-hidden="true" className="ion ion-ios-cloud-upload"></i> </div>
                            <div className="iq-step-text-area">

                                <h4 className="iq-step-title">SUNUNDRIVE</h4>

                                <span className="iq-step-desc">REWARDX TECHNOLOGY SERVICES LTDA</span>

                            </div>

                        </div>

                    </div>
                </div>
                <div className="col-lg-4  col-md-6">
                    <div className="iq-process iq-process-step-style-2 text-center">

                        <div className="iq-process-step">

                            <div className="iq-step-content">
                                <i aria-hidden="true" className="ion ion-ios-world"></i> </div>
                            <div className="iq-step-text-area">

                                <h4 className="iq-step-title">CNPJ</h4>

                                <span className="iq-step-desc">55.427.186/0001-51</span>

                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    </section>
    </GoogleReCaptchaProvider>
    )
}