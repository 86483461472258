/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import { Context } from '../../Context/AuthContext';
import { HubContext } from '../../Context/HubContext.js';
import { useParams } from 'react-router-dom';
import { Request, Req } from '../../scripts/Request';
import { Cache} from '../../scripts/Cache';
import { WaitObject } from '../../scripts/Wait';
import { Currency } from '../../scripts/StringUtils';
import ReactGA from 'react-ga';
import './index.css';



export default function Planos() {
    const component_name = "admin/home";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const { Hub } = useContext(HubContext);
    const timerIdleRef = useRef(new Date());
    const loadPrestineRef = useRef(false);
    const [pageLoading, setPageLoading] = useState(false);
    const [planos, setPlanos] = useState([]);
    const [affiliate, setAffiliate] = useState("");
    

    //#region Effects
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);

        setTimeout(async() => {
            let affiliate = await Cache.Get("affiliate");
            if(affiliate) {
                setAffiliate(affiliate);
            }
        }, 100)
            

        let wait = setTimeout(() => {
            if(!loadPrestineRef.current) {
                loadPrestineRef.current = true;
                Init();
            }
        }, 200);


        return () => {
            clearInterval(wait);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    Hub.Clear(component_name);
                } catch(e) {}
            }
        }
    },[]);

    //#endregion Effects


    //#region Init
     const Init = () => {
        LoadPlanos();
     }
    //#endregion Init


    //#region Loaders
    const LoadPlanos = async() => {
        await Req("/json/planos.json", "GET", "", "").then(data => {
            setPlanos(data);
        }).catch(err => { });
    }
    //#endregion Loaders



    return (
        <div className="Planos">
        <div className=" main-bg">
            <div className="container-fluid p-0">
                <div className="text-left iq-breadcrumb-one iq-bg-over black">
                    
                </div>
            </div>
        </div>

        <section className="iq-pricing-section">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="text-center iq-title-box iq-title-default iq-title-box-2">
                            <div className="iq-title-icon">
                            </div>
            
                            <h2 className="iq-title">  Um plano sob medida para você </h2>

                        </div>
                    </div>
                </div>


                <div className="row">
                    {Array.isArray(planos) && planos.length > 0 && planos.map((item, i) => (
                        <div key={'plan'+i} className="col-lg-4 col-md-6 col-sm-12">
                            <div className={'iq-price-container iq-price-table-6 text-center iq-box-shadow' + (item.active?' active ':'')}>
                                <div className="iq-price-header">
                                    <span className="iq-price-label">{item.name}</span>
                                    <h4 className="iq-price">{Currency(item.valor)}<span className="iq-price-desc">/mês</span></h4>
                                    <p className="iq-price-description">{item.description}</p>
                                </div>
                                <div className="iq-price-body">
                                    <ul className="iq-price-service">
                                        {Array.isArray(item.features) && item.features.length > 0 && item.features.map((feature, j) => (
                                            <li className="inactive">
                                                <span>{feature}</span>
                                                <i aria-hidden="true" className=""></i>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <div className="iq-price-footer">
                                    <div className="iq-btn-container">
                                        {/*<a className="iq-button iq-btn-round d-inline" href={'https://painel.sunundrive.com/Cadastro/'+ affiliate + '?plan_id=' + item.id}>
                                            Adquirir Plano
                                        </a>*/}

                                        <a className="iq-button iq-btn-round d-inline" href={'/Plano/'+item.url}>
                                            Saiba mais
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>   
                    ))}
                </div>


                <div className="row">
                    <div class="col-lg-3 col-md-12"></div>
                    
                    
                    <div class="col-lg-6 col-md-12 mb-lg-0 mb-4">
                        <a href="/Contato?dep=4" className="bussines_link">
                            <div class="iq-fancy-box iq-resorces  text-left active wow fadeInUp ">
                                <div class="iq-fancy-box-content">
                                    <div class="iq-img-area">
                                        <img src="images/others/04.png" class="img-fluid" title="img1" alt="qloud" /> </div>
                                    <div class="media-body">
                                        <h5 class="iq-fancy-title"> CLOUD BACKUP FOR BUSINESS</h5>

                                        <p class="fancy-box-content mb-0"> 
                                        Ideal para Empresas, Governo e Escolas<br /><br />

                                        - IaaS ou "On-Premises"<br />
                                        - Deploy, Monitoração e Suporte<br />
                                        - URL Personalizada<br />
                                        - Use a sua Logomarca<br />
                                        - Suporte por Ticket, E-mail e/ou Telefone<br />
                                        - Aplicativos de Nuvem Colaborativa<br />
                                        - Treinamento<br /><br />

                                        <center>
                                            <span className="iq-button iq-btn-round d-inline">
                                                Entre em contato
                                            </span>
                                        </center>
                                        </p>

                                    </div>

                                </div>
                            </div>
                        </a>
                    </div>
                    
                    <div class="col-lg-3 col-md-12"></div>
                </div>


                <div className="row">
                    <div class="col-lg-12 col-md-12">
                    <br /> <br />
                    "As bonificações são fixas: R$ 10,00 para o primeiro nível, R$ 5,00 para o segundo, R$ 3,00 para o terceiro, R$ 2,00 para o quarto e R$ 1,00 para o quinto nível, independentemente do valor do plano."
                    <br />
                    </div>
                </div>

             
            </div>
        </section>
        </div>
    )
}