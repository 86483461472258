/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import { Context } from '../../Context/AuthContext';
import { HubContext } from '../../Context/HubContext.js';
import { useParams } from 'react-router-dom';
import { Request } from '../../scripts/Request';
import { Cache} from '../../scripts/Cache';
import { WaitObject } from '../../scripts/Wait';
import ReactGA from 'react-ga';
import './index.css';



export default function Index() {
    const component_name = "sobre";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const { Hub } = useContext(HubContext);
    const timerIdleRef = useRef(new Date());
    const loadPrestineRef = useRef(false);

    //#region Effects
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
        let wait = setTimeout(() => {
            if(!loadPrestineRef.current) {
                loadPrestineRef.current = true;
                Init();
            }
        }, 200);

        return () => {
            clearInterval(wait);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    Hub.Clear(component_name);
                } catch(e) {}
            }
        }
    },[]);
    //#endregion Effects


    //#region Init
    const Init = () => {    
        
    }
    //#endregion Init


    return (
        <>
        <div className="iq-blog-section overview-block-ptb">
            <div className="container">

                <div className="row">
                    <div className="col-lg-8 col-sm-12 mt-lg-0 mt-5">
                    <article id="post-218"
                        className="post-218 post type-post status-publish format-standard has-post-thumbnail hentry category-marketing tag-business tag-marketing">
                        <div className="iq-blog-box">
                            <div className="iq-blog-image clearfix">
                                <img src="images/blog/sobre_01.png" className="img-fluid" alt="sobre o SUNUNDRIVE" />
                                <ul className="iq-blogtag">
                                <li><a>Sobre a SUNUNDRIVE</a></li>
                                </ul>

                            </div>
                            <div className="iq-blog-detail">
                                <div className="iq-blog-meta">
                                <ul className="iq-postdate">
                                    <li className="list-inline-item">
                                        <i className="fa fa-check mr-2"></i>
                                        <span className="screen-reader-text"></span>
                                        <a rel="bookmark"><time className="entry-date published updated" datetime="">Confiável para Seus Arquivos, Rentável para o Seu Bolso</time></a>
                                    </li>
                                </ul>

                                </div>
                                <div className="blog-content">
        
                                <br />

                                <p>O <b>SUNUNDRIVE</b> é um serviço de armazenamento e backup de dados em nuvem, inspirado na paradisíaca Praia da Sununga, em Ubatuba/SP. Desde meados de 2021, o fundador da empresa desenvolve o projeto com a visão de criar uma solução prática e essencial para o cotidiano, que também ofereça aos seus usuários e afiliados a oportunidade de gerar renda extra, proporcionando-lhes a chance de aproveitar mais a vida.</p>
                                <br />


                                <blockquote className="wp-block-quote">
                                    <p><b>INFRAESTRUTURA DE ARMAZENAMENTO</b><br /> <br />

                                    Nossa infraestrutura está inteiramente hospedada no Brasil, utilizando datacenters de ponta que garantem um ambiente de segurança e confiabilidade excepcionais. Cada aspecto da nossa operação é conduzido com o mais alto padrão de qualidade, desde o rigoroso controle de acesso até a robustez dos equipamentos de última geração que suportam nossa plataforma. Seguimos as melhores práticas de armazenamento digital, segurança da informação e proteção de dados, em total conformidade com a Lei Geral de Proteção de Dados (LGPD). Nosso compromisso é proporcionar uma experiência de armazenamento segura, eficiente e altamente disponível, assegurando que os dados de nossos clientes estejam sempre protegidos e acessíveis.</p>
                                </blockquote>
                                

                                <p><b>RESPONSABILIDADE FISCAL</b><br /> <br />
                                Com atenção meticulosa aos detalhes e um profundo compromisso com a ética, nossa estratégia de negócio é totalmente alinhada com as exigências legais civis, tributárias e fiscais. Todos os planos vendidos pela SUNUNDRIVE têm seus impostos devidamente recolhidos, e todas as bonificações pagas pelas vendas diretas realizadas por nossos afiliados têm os impostos retidos na fonte, conforme exigido pela Receita Federal. Este processo é automatizado, garantindo conformidade durante o recebimento das mensalidades e no pagamento das bonificações.
                                </p>

                                <br /> <br />
                                <p><b>VENDAS DIRETAS E MARKETING MULTINÍVEL</b><br /> <br />
                                O SUNUNDRIVE não é apenas um serviço de armazenamento em nuvem; também oferecemos uma oportunidade de negócio para nossos clientes, que podem se tornar afiliados e revender nosso serviço. Assim como plataformas conhecidas como Hotmart, Polishop e Amway, nossos afiliados têm a chance de ganhar bonificações significativas através de vendas diretas.
                                </p>  

                                <p>
                                Nosso sistema de marketing multinível permite que os afiliados recebam bonificações em até 5 níveis, recompensando tanto as vendas diretas quanto a formação de uma rede de clientes. Seguimos rigorosamente todas as diretrizes da Associação Brasileira de Empresas de Vendas Diretas (ABEVD) e as leis brasileiras para garantir que nosso modelo de negócio seja sério, ético e legal, afastando qualquer associação com esquemas de pirâmide financeira.
                                </p> 

                                </div>

                            </div>
                        </div>
                       
                    </article>
                    </div>


                    <div className="col-lg-4 col-sm-12 mt-lg-0 mt-5">
                    <aside id="secondary" className="widget-area" aria-label="Blog Sidebar">
                        
                        <div id="recent-posts-2" className="widget widget_recent_entries">
                            <img src="images/dispositivos.png" className="img-fluid" alt="sobre o SUNUNDRIVE" /> <br /><br />
                            Transferência rápida e eficiente de seu dispositivo para nossa nuvem.
                        </div>


                        <div id="recent-posts-2" className="widget widget_recent_entries">
                            <img src="images/seguranca.png" className="img-fluid" alt="sobre o SUNUNDRIVE" /> 
                            Segurança de ponta com alta disponibilidade para seus dados mais valiosos.
                        </div>

                        <div id="recent-posts-2" className="widget widget_recent_entries">
                            <img src="images/renda.jpg" className="img-fluid" alt="sobre o SUNUNDRIVE" /> 
                            Ganhe renda extra mensal com nosso programa de afiliados por indicações que você faz de nossa plataforma.
                        </div>

                        <div id="recent-posts-2" className="widget widget_recent_entries">
                            <img src="images/marketing.png" className="img-fluid" alt="sobre o SUNUNDRIVE" /> <br /><br />
                            Expanda suas oportunidades com nosso sistema de marketing multinível com até 5 níveis de bonificação por vendas diretas.
                        </div>
                    </aside>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}