/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import { Context } from '../../Context/AuthContext';
import { HubContext } from '../../Context/HubContext.js';
import { useParams } from 'react-router-dom';
import { Request } from '../../scripts/Request';
import { Cache} from '../../scripts/Cache';
import { WaitObject } from '../../scripts/Wait';
import ReactGA from 'react-ga';
import './index.css';


export default function Index() {
    const component_name = "faq";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const { Hub } = useContext(HubContext);
    const timerIdleRef = useRef(new Date());
    const loadPrestineRef = useRef(false);


    //#region Effects
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
        let wait = setTimeout(() => {
            if(!loadPrestineRef.current) {
                loadPrestineRef.current = true;
                Init();
            }
        }, 200);

        return () => {
            clearInterval(wait);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    Hub.Clear(component_name);
                } catch(e) {}
            }
        }
    },[]);
    //#endregion Effects


    //#region Init
    const Init = () => {    
        window.jQuery('.iq-accordion .iq-accordion-block .iq-accordion-details').hide();
        window.jQuery('.iq-accordion .iq-accordion-block:first').addClass('iq-active').children().slideDown('slow');
        window.jQuery('.iq-accordion .iq-accordion-block').on("click", function() {
            if (window.jQuery(this).children('div.iq-accordion-details').is(':hidden')) {
                window.jQuery('.iq-accordion .iq-accordion-block').removeClass('iq-active').children('div.iq-accordion-details').slideUp('slow');
                window.jQuery(this).toggleClass('iq-active').children('div.iq-accordion-details').slideDown('slow');
            }
        });    
    }
    //#endregion Init



    return (
        <>
        <section className="iq-pricing-section">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="text-center iq-title-box iq-title-default iq-title-box-2">
                            <div className="iq-title-icon">
                            </div>
                            {/*<span className="iq-subtitle">Faq</span>*/}
                            <h2 className="iq-title">Respostas para perguntas frequentes</h2>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="iq-accordion  iq-accordion-semi-round iq-accordion-classic">

                            <div className="iq-accordion-block  1 accordion-active">
                                <div className="iq-accordion-title">
                                    <div className="iq-icon-right"><i aria-hidden="true" className="ion ion-minus-round active"></i><i aria-hidden="true" className="ion ion-plus inactive"></i></div>
                                    <h5 className="mb-0 accordion-title">
                                    O que é o SUNUNDRIVE?

                        </h5>
                                </div>

                                <div className="iq-accordion-details">

                                    <p className="iq-content-text"> O SUNUNDRIVE é um serviço de armazenamento e backup de dados em nuvem. Inspirado na paradisíaca Praia da Sununga, em Ubatuba/SP, o SUNUNDRIVE foi desenvolvido para oferecer uma solução prática e segura para o armazenamento de dados, permitindo acesso fácil e seguro de qualquer lugar do mundo. </p>

                                </div>
                            </div>

                            <div className="iq-accordion-block   2">
                                <div className="iq-accordion-title">
                                    <div className="iq-icon-right"><i aria-hidden="true" className="ion ion-minus-round active"></i><i aria-hidden="true" className="ion ion-plus inactive"></i></div>
                                    <h5 className="mb-0 accordion-title">
                                    Como funciona o serviço de backup do SUNUNDRIVE?

                        </h5>
                                </div>

                                <div className="iq-accordion-details">

                                    <p className="iq-content-text"> O SUNUNDRIVE permite que você faça o upload manual dos seus arquivos diretamente para nossa plataforma a partir do seu computador, tablet ou celular. Uma vez enviados, seus arquivos ficam armazenados em nossos servidores seguros e podem ser acessados de qualquer lugar do mundo a qualquer momento. </p>

                                </div>
                            </div>

                  

                            <div className="iq-accordion-block   3">
                                <div className="iq-accordion-title">
                                    <div className="iq-icon-right"><i aria-hidden="true" className="ion ion-minus-round active"></i><i aria-hidden="true" className="ion ion-plus inactive"></i></div>
                                    <h5 className="mb-0 accordion-title">
                                    Como posso ganhar dinheiro com o SUNUNDRIVE?

                        </h5>
                                </div>

                                <div className="iq-accordion-details">

                                    <p className="iq-content-text"> O SUNUNDRIVE oferece um programa de marketing multinível, onde você pode ganhar bonificações ao indicar novos clientes para o serviço. As bonificações são distribuídas em até 5 níveis, proporcionando ganhos recorrentes mensais com base nas assinaturas indicadas + Bônus por subida de Ranking. Explicação em: https://sunundrive.com/ComoFunciona </p>

                                </div>
                            </div>

                            <div className="iq-accordion-block   4">
                                <div className="iq-accordion-title">
                                    <div className="iq-icon-right"><i aria-hidden="true" className="ion ion-minus-round active"></i><i aria-hidden="true" className="ion ion-plus inactive"></i></div>
                                    <h5 className="mb-0 accordion-title">
                                    Como funciona o sistema de bonificação do marketing multinível da SUNUNDRIVE?

                        </h5>
                                </div>

                                <div className="iq-accordion-details">

                                    <p className="iq-content-text"> Nosso sistema de bonificação funciona da seguinte forma:
<br /><br />
Primeiro Nível: R$ 10,00 por cada assinatura indicada diretamente por você.<br />
Segundo Nível: R$ 5,00 por cada assinatura indicada pelos seus indicados diretos.<br />
Terceiro Nível: R$ 3,00 por cada assinatura indicada pelo segundo nível.<br />
Quarto Nível: R$ 2,00 por cada assinatura indicada pelo terceiro nível.<br />
Quinto Nível: R$ 1,00 por cada assinatura indicada pelo quarto nível.</p>

                                </div>
                            </div>

                            <div className="iq-accordion-block   5">
                                <div className="iq-accordion-title">
                                    <div className="iq-icon-right"><i aria-hidden="true" className="ion ion-minus-round active"></i><i aria-hidden="true" className="ion ion-plus inactive"></i></div>
                                    <h5 className="mb-0 accordion-title">
                                    Existem deduções nos valores de bonificação que recebo pela SUNUNDRIVE?

                                </h5>
                                </div>

                                <div className="iq-accordion-details">

                                    <p className="iq-content-text"> 
                                    Sim, todas as bonificações sofrem uma dedução de 11% referente ao INSS + alíquota de IR aplicável para o valor bruto recebido seguindo a tabela de IR vigente, retidos na fonte conforme exigido pela legislação brasileira.</p>

                                </div>
                            </div>

                            <div className="iq-accordion-block   6">
                                <div className="iq-accordion-title">
                                    <div className="iq-icon-right"><i aria-hidden="true" className="ion ion-minus-round active"></i><i aria-hidden="true" className="ion ion-plus inactive"></i></div>
                                    <h5 className="mb-0 accordion-title">
                                    Como são calculados os ganhos mensais no programa de marketing multinível da SUNUNDRIVE?

                                </h5>
                                </div>

                                <div className="iq-accordion-details">

                                    <p className="iq-content-text"> 
                                    Seus ganhos mensais são calculados com base no número de assinaturas indicadas em cada nível e após a dedução de 11% de INSS + IR com base no seu valor recebido. Por exemplo, se você indicar 10 pessoas diretamente, e cada uma dessas pessoas indicar outras 10, e assim por diante até o quinto nível, seus ganhos seriam:
                                    <br /><br />
                                    Primeiro Nível: R$ 100,00<br />
                                    Segundo Nível: R$ 500,00<br />
                                    Terceiro Nível: R$ 3.000,00<br />
                                    Quarto Nível: R$ 20.000,00<br />
                                    Quinto Nível: R$ 100.000,00<br /><br />
                                    <small>Total Mensal Bruto de: R$ 123.600,00 </small><br />
                                    Valor Líquido (considerando tributações) de <b>R$ 89.885,07</b>.
                                    <br /><br />
                                    Existem ainda aplicáveis Bônus de subida de Ranking que são melhor descritos em <a href="https://sunundrive.com/ComoFunciona">https://sunundrive.com/ComoFunciona</a>
                                    </p>

                                </div>
                            </div>

                            <div className="iq-accordion-block   7">
                                <div className="iq-accordion-title">
                                    <div className="iq-icon-right"><i aria-hidden="true" className="ion ion-minus-round active"></i><i aria-hidden="true" className="ion ion-plus inactive"></i></div>
                                    <h5 className="mb-0 accordion-title">
                                    O modelo de negócios do SUNUNDRIVE é legal e ético?

                                </h5>
                                </div>

                                <div className="iq-accordion-details">

                                    <p className="iq-content-text"> 
                                    Sim, o SUNUNDRIVE segue rigorosamente as diretrizes da Associação Brasileira de Empresas de Vendas Diretas (ABEVD) e todas as leis brasileiras aplicáveis. Nosso modelo de negócio é transparente, ético e totalmente legal, garantindo que não estamos associados a esquemas de pirâmide financeira.
                                    </p>

                                </div>
                            </div>

                            <div className="iq-accordion-block   8">
                                <div className="iq-accordion-title">
                                    <div className="iq-icon-right"><i aria-hidden="true" className="ion ion-minus-round active"></i><i aria-hidden="true" className="ion ion-plus inactive"></i></div>
                                    <h5 className="mb-0 accordion-title">
                                    Onde está localizada a infraestrutura do SUNUNDRIVE?

                                </h5>
                                </div>

                                <div className="iq-accordion-details">

                                    <p className="iq-content-text"> 
                                    Toda a nossa infraestrutura está localizada em datacenters de ponta no Brasil, que seguem as melhores práticas de segurança, controle de acesso e qualidade dos equipamentos, garantindo alta disponibilidade e proteção dos dados.
                                    </p>

                                </div>
                            </div>

                            <div className="iq-accordion-block   9">
                                <div className="iq-accordion-title">
                                    <div className="iq-icon-right"><i aria-hidden="true" className="ion ion-minus-round active"></i><i aria-hidden="true" className="ion ion-plus inactive"></i></div>
                                    <h5 className="mb-0 accordion-title">
                                    Como posso acessar meus arquivos armazenados no SUNUNDRIVE?

                                </h5>
                                </div>

                                <div className="iq-accordion-details">

                                    <p className="iq-content-text"> 
                                    Você pode acessar seus arquivos armazenados no SUNUNDRIVE a qualquer momento e de qualquer lugar do mundo através do nosso site, utilizando seu computador, tablet ou celular. Basta fazer login na plataforma para ter acesso aos seus dados.
                                    </p>

                                </div>
                            </div>

                            <div className="iq-accordion-block   10">
                                <div className="iq-accordion-title">
                                    <div className="iq-icon-right"><i aria-hidden="true" className="ion ion-minus-round active"></i><i aria-hidden="true" className="ion ion-plus inactive"></i></div>
                                    <h5 className="mb-0 accordion-title">
                                    Como posso me tornar um afiliado do SUNUNDRIVE?

                                </h5>
                                </div>

                                <div className="iq-accordion-details">

                                    <p className="iq-content-text"> 
                                    Para se tornar um afiliado do SUNUNDRIVE, basta se inscrever em nosso programa de afiliados através do site. Após a inscrição, você receberá um link pessoal para começar a indicar novos clientes e ganhar bonificações.
                                    </p>

                                </div>
                            </div>

                            <div className="iq-accordion-block   11">
                                <div className="iq-accordion-title">
                                    <div className="iq-icon-right"><i aria-hidden="true" className="ion ion-minus-round active"></i><i aria-hidden="true" className="ion ion-plus inactive"></i></div>
                                    <h5 className="mb-0 accordion-title">
                                    O que é a Lei Geral de Proteção de Dados (LGPD)?

                                </h5>
                                </div>

                                <div className="iq-accordion-details">

                                    <p className="iq-content-text"> 
                                    A Lei Geral de Proteção de Dados (LGPD) é uma legislação brasileira que regulamenta o tratamento de dados pessoais, garantindo a privacidade e proteção das informações dos usuários. O SUNUNDRIVE segue rigorosamente todas as diretrizes da LGPD para garantir a segurança e confidencialidade dos dados armazenados.
                                    </p>

                                </div>
                            </div>

                            <div className="iq-accordion-block   12">
                                <div className="iq-accordion-title">
                                    <div className="iq-icon-right"><i aria-hidden="true" className="ion ion-minus-round active"></i><i aria-hidden="true" className="ion ion-plus inactive"></i></div>
                                    <h5 className="mb-0 accordion-title">
                                    Quais são as formas de pagamento aceitas pelo SUNUNDRIVE?

                                </h5>
                                </div>

                                <div className="iq-accordion-details">

                                    <p className="iq-content-text"> 
                                    O SUNUNDRIVE aceita apenas cartões de crédito. Todas as transações são seguras e protegidas por criptografia de ponta a ponta.
                                    </p>

                                </div>
                            </div>

                            <div className="iq-accordion-block   13">
                                <div className="iq-accordion-title">
                                    <div className="iq-icon-right"><i aria-hidden="true" className="ion ion-minus-round active"></i><i aria-hidden="true" className="ion ion-plus inactive"></i></div>
                                    <h5 className="mb-0 accordion-title">
                                    O SUNUNDRIVE oferece suporte ao cliente?

                                </h5>
                                </div>

                                <div className="iq-accordion-details">

                                    <p className="iq-content-text"> 
                                    Sim, o SUNUNDRIVE oferece suporte ao cliente para ajudar com quaisquer dúvidas ou problemas. Nosso suporte está disponível através do nosso site, por meio do formulário de contato e dentro do Painel se você já for nosso cliente.
                                    </p>

                                </div>
                            </div>

                            <div className="iq-accordion-block   14">
                                <div className="iq-accordion-title">
                                    <div className="iq-icon-right"><i aria-hidden="true" className="ion ion-minus-round active"></i><i aria-hidden="true" className="ion ion-plus inactive"></i></div>
                                    <h5 className="mb-0 accordion-title">
                                    Posso cancelar minha assinatura do SUNUNDRIVE a qualquer momento?

                                </h5>
                                </div>

                                <div className="iq-accordion-details">

                                    <p className="iq-content-text"> 
                                    Sim, você pode cancelar sua assinatura do SUNUNDRIVE a qualquer momento através do seu painel de controle na plataforma. Após o cancelamento, seus dados permanecerão disponíveis por um período de até 30 dias antes de serem excluídos definitivamente.
                                    </p>

                                </div>
                            </div>

                            <div className="iq-accordion-block   15">
                                <div className="iq-accordion-title">
                                    <div className="iq-icon-right"><i aria-hidden="true" className="ion ion-minus-round active"></i><i aria-hidden="true" className="ion ion-plus inactive"></i></div>
                                    <h5 className="mb-0 accordion-title">
                                    Como o SUNUNDRIVE garante a segurança dos meus dados?

                                </h5>
                                </div>

                                <div className="iq-accordion-details">

                                    <p className="iq-content-text"> 
                                    O SUNUNDRIVE utiliza as melhores práticas de segurança da informação, incluindo criptografia de dados, controle de acesso rigoroso e monitoramento contínuo dos nossos sistemas. Nossa infraestrutura em datacenters de ponta no Brasil garante alta disponibilidade e proteção contra ameaças.
                                    </p>

                                </div>
                            </div>

                            <div className="iq-accordion-block   16">
                                <div className="iq-accordion-title">
                                    <div className="iq-icon-right"><i aria-hidden="true" className="ion ion-minus-round active"></i><i aria-hidden="true" className="ion ion-plus inactive"></i></div>
                                    <h5 className="mb-0 accordion-title">
                                    O que acontece se eu esquecer minha senha do SUNUNDRIVE?

                                </h5>
                                </div>

                                <div className="iq-accordion-details">

                                    <p className="iq-content-text"> 
                                    Se você esquecer sua senha, pode redefini-la facilmente através da opção "Esqueci minha senha" na página de login. Um link de redefinição de senha será enviado para o seu e-mail cadastrado.
                                    </p>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}