/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import { Context } from '../../Context/AuthContext';
import { HubContext } from '../../Context/HubContext.js';
import { useParams } from 'react-router-dom';
import { Request } from '../../scripts/Request';
import { Cache} from '../../scripts/Cache';
import { WaitObject } from '../../scripts/Wait';
import ReactGA from 'react-ga';
import './index.css';



export default function Index() {
    const component_name = "privacidade";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const { Hub } = useContext(HubContext);
    const timerIdleRef = useRef(new Date());
    const loadPrestineRef = useRef(false);

    //#region Effects
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
        let wait = setTimeout(() => {
            if(!loadPrestineRef.current) {
                loadPrestineRef.current = true;
                Init();
            }
        }, 200);

        return () => {
            clearInterval(wait);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    Hub.Clear(component_name);
                } catch(e) {}
            }
        }
    },[]);
    //#endregion Effects


    //#region Init
    const Init = () => {    
        
    }
    //#endregion Init


    return (
        <>
       <div className="iq-blog-section overview-block-ptb">
            <div className="container">

                <div className="row">
                    <div className="col-lg-8 col-sm-12 mt-lg-0 mt-5">
                    <article id="post-218"
                        className="post-218 post type-post status-publish format-standard has-post-thumbnail hentry category-marketing tag-business tag-marketing">
                        <div className="iq-blog-box">
                            <div className="iq-blog-image clearfix">
                                <img src="images/privacidade.png" className="img-fluid" alt="sobre o SUNUNDRIVE" />
                                <ul className="iq-blogtag">
                                <li><a>POLÍTICA DE PRIVACIDADE DA SUNUNDRIVE</a></li>
                                </ul>

                            </div>
                            <div className="iq-blog-detail">
                                <div className="iq-blog-meta">
                                <small>Data de Publicação: 18/07/2024<br />
                                Data da Entrada em Vigor: 18/07/2024</small>

                                </div>
                                <div className="blog-content">
        
                                    <br />
                                    <h5>Seja bem-vindo à Sunundrive</h5>
                                    <br />

                                    <p>
                                    Ao contratar nosso serviço de armazenamento na nuvem ou ingressar em nosso Programa de Afiliados, você confia seus dados e informações pessoais a nós. E por isso temos comprometimento com sua segurança e confiança.  
                                    </p>

                                    <p>
                                    Nossa Política de Privacidade explicará a você, de forma clara e acessível, quais serão seus dados pessoais coletados, com quem serão compartilhados e em que locais serão armazenados. 
                                    </p>

                                    <p>
                                    O DPOnet, inscrito no CNPJ sob o nº 36.487.128/0001-79, foi nomeado como nosso Encarregado de Dados. Assim, ele está apto a exercer todas as funções e atribuições do cargo, previstas na Lei Geral de Proteção de Dados Pessoais (LGPD).    
                                    </p>

                                    <p>
                                    Caso você deseje exercer qualquer um de seus direitos, expressos na LGPD, basta clicar no Selo “Canal de Comunicação LGPD”, em nosso site, e preencher o “Formulário de Atendimento ao Titular de Dados”.    
                                    </p>

                                    <p>
                                    Ao acessar e navegar por nosso portal, você aceita nosso Aviso, estando ciente e em total acordo com a forma como utilizaremos suas informações pessoais. 
                                    </p>

                                    <p>
                                    O presente documento deve ser lido em conjunto com os nossos Termos de Uso.    
                                    </p>

                                    <br /><br />
                                    <h5>1.	INFORMAÇÕES COLETADAS:</h5>
                                    Para que você interaja conosco, nós coletamos os seguintes tipos de informações: 
                                    <br /><br />

                                    
                                    <b>I.	Informações fornecidas por você: </b><br /> 
                                    <p>-	Dados de cadastro: quando você se cadastra em nosso site, nos fornece as seguintes informações: a) dados do usuário:  nome, e-mail, CPF ou CNPJ; b) dados do pagante: celular, cpf, nome completo, e-mail e data de nascimento; c) para pagamento: bandeira do cartão de crédito, número do cartão de crédito, código de segurança do cartão de crédito, data de vencimento do cartão de crédito, nome do titular do cartão de crédito e  cpf e, d) endereço de cobrança: estado, cidade, rua, número, bairro, cep e complemento.
                                    </p><p>-	Informações de autenticação: com o objetivo de proporcionar um ambiente de navegação seguro, podemos solicitar as seguintes informações de identificação e autenticação: e-mail e telefone. 
                                    </p>
                                    
                                    <br /><br />

                                    <b>II.	Informações geradas automaticamente: </b><br /> 
                                    <p>-	Registros de acesso: a Sunundrive coleta, de forma automática, os registros de acesso à aplicação, que podem incluir o endereço de IP utilizado para acessar nosso site, com data e hora. Esses dados são de coleta obrigatória, conforme determinado pela Lei nº 12.295/2014, também conhecida como Marco Civil da Internet, mas serão fornecidos para terceiros apenas com a sua autorização expressa, ou em razão de decisão judicial. 
                                    </p><p>-	Comunicação com a organização: quando você se comunica com a organização, coletamos informações sobre a sua comunicação, incluindo metadados como: endereço de IP, data, hora e conteúdo das comunicações, como qualquer informação que você escolha fornecer. 
                                    </p><p>-	Cookies e tecnologias semelhantes: nós utilizamos cookies, isto é, arquivos de texto gerados e armazenados no seu navegador </p>


                                    <br /><br />
                                    <h5>2. COMO USAMOS SEUS DADOS :</h5><br />
                                    Nós prezamos muito pela sua privacidade e é justamente por isso que todos os dados e informações sobre você são tratadas com a mais alta confidencialidade, sendo usados somente para os fins aqui descritos, e por você conhecidos e autorizados
                                    <br /><br />

                                    <ul class="letter-list">
                                        <li>
                                            <b>DADOS PESSOAIS</b><br />
                                            <p>Podemos utilizá-los para:<br />
i. Permitir que você acesse e utilize todas as funcionalidades do nosso portal; <br />
ii. Cumprir obrigações legais; <br />
iii. Enviar mensagens, alertas, notificações e atualizações a respeito de suporte ou serviço prestado, promoções, notícias, atualizações, eventos e outros assuntos que você possa ter interesse;<br />
iv. Detecção e prevenção de fraudes, spam e incidentes de segurança; <br />
v. Verificar ou autenticar as informações fornecidas por você, inclusive comparando a dados coletados de outras fontes;<br />
vi. Para qualquer fim que você autorizar, no momento da coleta de dados. <br /><br />
Poderemos, eventualmente, utilizar dados para finalidades diversas das previstas nesta Política de Privacidade, mas todas elas estarão dentro das suas legítimas expectativas. O eventual uso dos seus dados para finalidades que não cumpram com essa prerrogativa será feito mediante sua autorização prévia. <br />
</p>
                                        </li>

                                        <li>
                                            <b>EXCLUSÃO DOS DADOS</b><br />
                                            Todos os dados pessoais coletados serão excluídos de nossos servidores mediante sua requisição, por procedimento gratuito e facilitado, ou quando estes deixarem de ser necessários ou relevantes para o oferecimento de nossos serviços, salvo se houver qualquer outra razão para a sua manutenção, como eventual obrigação legal de retenção de dados ou necessidade de preservação destes para resguardo de direitos da organização.  
                                        </li>
                                    </ul>

                                    <br /><br />
                                    <h5>3. COMPARTILHAMENTO DE INFORMAÇÕES</h5><br />
                                    Todas as informações que armazenamos sobre você podem ser consideradas ativos no caso de negociações em que a Sunundrive fizer parte. Nesse sentido, nos reservamos no direito de, por exemplo, incluir seus dados dentre os ativos da empresa caso esta venha a ser vendida, adquirida ou fundida com outra. Por meio desta Política de Privacidade, você concorda e está ciente desta possibilidade.
                                    A organização deverá fornecer seus dados e informações, caso seja requisitado por meio de decisão judicial.
                                    <br /><br />

                                    <h5>4. DIREITO DOS TITULARES</h5><br />
                                    A LGPD (Lei Geral de Proteção de Dados) estabelece que o titular (Você) tem o direito de obter do controlador (a Sunundrive), em relação às suas informações pessoais por ele tratados, a qualquer momento e mediante requisição: 
                                    <br /><br />

                                    <p>
                                    -	A confirmação da existência de tratamento;<br />
-	Acesso aos dados;<br />
-	Correção de dados incompletos, inexatos ou desatualizados;<br />
-	Anonimização, bloqueio ou eliminação de dados desnecessários ou tratados em desconformidade com a Lei;<br />
-	Portabilidade dos dados;<br />
-	Eliminação dos dados pessoais tratados com o seu consentimento;<br />
-	Informações das entidades públicas e privadas com as quais o controlador realizou uso compartilhado de dados;<br />
-	Informações sobre a possibilidade de não fornecer seu consentimento e as consequências da negativa;<br />
-	Revogação do consentimento. <br />

                                    </p>

                                    <p>
                                    Caso o titular de dados queira exercer qualquer um de seus direitos expressos na Lei, bem como tirar dúvidas referentes a esta documentação, basta clicar no Selo “Canal de Comunicação LGPD” em nosso site e preencher o “Formulário de Atendimento ao Titular de Dados”. 
                                    </p>

                                    <p>
                                    Por meio do Selo, qualquer interessado é direcionado ao Portal Privacidade&Você e pode verificar a autenticidade da adequação da empresa à LGPD, inclusive, podendo verificar: a última revisão de seus mecanismos de controle com a exibição da data e hora em que foi feita a revisão; as políticas de privacidade da empresa; o meio de contato dos titulares de dados; e o meio de contato da ANPD (Autoridade Nacional de Proteção de Dados).
                                    </p>

                                    <br /><br />
                                    <h5>5. SEGURANÇA DAS INFORMAÇÕES</h5><br />
                                    Todos os seus dados são confidenciais e somente pessoas autorizadas terão acesso a eles.
                                    <br /><br />

                                    <p>
                                    Nós empenhamos todos os esforços razoáveis de mercado para garantir a segurança dos nossos sistemas e dos seus dados. Nossos servidores estão localizados em diferentes locais, para garantir estabilidade e segurança, podendo somente ser acessados por meio de canais de comunicação previamente autorizados. 
                                    </p>

                                    <p>
                                    Todas as suas informações serão, sempre que possível, criptografadas, caso não inviabilizem o seu uso pela plataforma. A qualquer momento você poderá requisitar cópia dos seus dados armazenados em nossos sistemas. Manteremos os dados e informações somente até quando estas forem necessárias ou relevantes para as finalidades descritas nesta Política de Privacidade, ou em caso de períodos pré-determinados por Lei, ou até quando estas forem necessárias para a manutenção de interesses legítimos da organização.  
                                    </p>

                                    <p>
                                    É importante esclarecer que não temos como garantir que todos os seus dados em nossa plataforma estarão livres de acessos não autorizados, principalmente caso haja compartilhamento indevido das senhas necessárias para acessar a nossa plataforma.
                                    </p>

                                    <p>
                                    Por isso, recomendamos que você mantenha sua senha de acesso em local seguro, sendo desaconselhado o compartilhamento desta com terceiros. Você se compromete a notificar a organização, imediatamente, através de meio seguro, a respeito de qualquer uso não autorizado de sua conta, bem como o acesso não autorizado por terceiros a esta.  
                                    </p>

                                    <br /><br />
                                    <h5>6. ATUALIZAÇÃO DA POLÍTICA DE PRIVACIDADE DA SUNUNDRIVE</h5><br />
                                    Nos reservamos o direito de atualizar nossa Política de Privacidade quantas vezes forem necessárias, a fim de fornecer a você informações mais seguras e melhorar cada vez mais a sua experiência. 
                                    <br /><br />

                                    <br /><br />
                                    <h5>7. LEGISLAÇÃO APLICÁVEL:</h5><br />
                                    Este documento é regido e deve ser interpretado conforme as leis e atos normativos que compõem o ordenamento jurídico brasileiro. Fica eleito o Foro da Comarca de Santana do Parnaíba/SP, como o competente para dirimir eventuais questões oriundas do presente documento, com expressa renúncia a qualquer outro.
                                    <br /><br />



                                    <br /><br /><br />
                                </div>

                            </div>
                        </div>
                       
                    </article>
                    </div>


                    <div className="col-lg-4 col-sm-12 mt-lg-0 mt-5">
                    <aside id="secondary" className="widget-area" aria-label="Blog Sidebar">
                        
                        <div id="recent-posts-2" className="widget widget_recent_entries">
                            <img src="images/dispositivos.png" className="img-fluid" alt="sobre o SUNUNDRIVE" /> <br /><br />
                            Transferência rápida e eficiente de seu dispositivo para nossa nuvem.
                        </div>


                        <div id="recent-posts-2" className="widget widget_recent_entries">
                            <img src="images/seguranca.png" className="img-fluid" alt="sobre o SUNUNDRIVE" /> 
                            Segurança de ponta com alta disponibilidade para seus dados mais valiosos.
                        </div>

                        <div id="recent-posts-2" className="widget widget_recent_entries">
                            <img src="images/renda.jpg" className="img-fluid" alt="sobre o SUNUNDRIVE" /> 
                            Ganhe renda extra mensal com nosso programa de afiliados por indicações que você faz de nossa plataforma.
                        </div>

                        <div id="recent-posts-2" className="widget widget_recent_entries">
                            <img src="images/marketing.png" className="img-fluid" alt="sobre o SUNUNDRIVE" /> <br /><br />
                            Expanda suas oportunidades com nosso sistema de marketing multinível com até 5 níveis de bonificação por vendas diretas.
                        </div>
                    </aside>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}